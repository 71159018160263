// Libs
import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { css } from "emotion";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import Spinner from "../../ui/Spinner";
import PageContainer from "../../ui/PageContainer";
import CheckOutModal from "./CheckOutModal";

// Actions / Redux
import { addToast } from "../../../actions/uiActions";
import ErrorBox from "../../ui/ErrorBox";
import { autoSignIn } from "../../../actions/authActions";

const CheckOutEmployees = props => {

  // Booking dd from params (url)
  const [shift, setShift] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState(false);

  const { shiftId } = props.match.params;



  // Checkout an employee
  const loadData = async () => {
    setLoading(true);
    try {
      const { data: shift } = await req()(
        `shifts/employee/${shiftId}/checkout`
      );

      setShift(shift);
      setLoading(false);
    } catch (err) {
      props.addToast({
        message: "Kunne ikke hente oplysningerne..",
        type: "error"
      });
      setError(true);
      setLoading(false);
    }
  };
  const submitCheckout = async (checkOut) => {
    try {
      setLoading(true);
      // submit checkout
      await req().put(`shifts/employee/submit-checkout`, checkOut);
      // Toaster message
      props.addToast({
        message: "Du har tjekket din booking ud!",
        type: "success",
        autoDismiss: true,
      });
      loadData();
      // update user profile.
      props.autoSignIn();
    } catch (err) {
      // Toaster message
      props.addToast({
        message: err?.response?.data || "Noget gik galt",
        type: "error",
        autoDismiss: true,
      });
      setLoading(false);
    }
  };
  const submitRating = async (rating) => {
    try {
      await req().put(`shifts/employee/submit-checkout-rating`, rating);
      // Toaster message
      props.addToast({
        message: "Bedømmelse gemt!",
        type: "success",
        autoDismiss: true,
      });
    } catch (err) {
      // Toaster message
      props.addToast({
        message: err?.response?.data || "Noget gik galt",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  // Get the employees shifts and the booking information
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);
  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle()}>
        {loading && !error && <Spinner title={"Henter vagter..."} />}
        {!loading && error && <ErrorBox />}
        {!loading && !error && (
          <Fragment>
            {/** Section 1 **/}
            <div className="section-two">
                <div className="employee-container">
                  <CheckOutModal
                    submitCheckout={submitCheckout}
                    submitRating={submitRating}
                    {...shift}
                  />
                </div>
            </div>
          </Fragment>
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-two {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .employee-container {
    width: 100%;
    padding: 0.25rem;
  }
  .auto-fill-container {
    width:50%;
    text-align: center;
    .auto-fill{
      background-color: #979797;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 0.25rem;
      padding: 0.3rem 1rem;
      outline: none;
    }
  }
  @media screen and (max-width: 640px){
    .section-two{
      justify-content: center;
    }
  }

`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch),
  autoSignIn: bindActionCreators(autoSignIn, dispatch)
});

export default connect(null, mapDispatchToProps)(CheckOutEmployees);
