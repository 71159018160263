// Libs
import React, { Fragment, useRef, useState, useEffect } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTooltip from "react-tooltip";

// Styles
import ReactStars from "react-stars";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import colors from "../../../styles/colors";
import HelpCircleIcon from "mdi-react/HelpCircleIcon";

// Components
import CircularRating from "../../ui/CircularRating";
import PastEmployeeRatings from "../PastEmployeeRatings";
import CancelledEmployeeShifts from "../CancelledEmployeeShifts";

// Actions
import { hideModal, showModal } from "../../../actions/uiActions";

// Utilities
import req from "../../../utilities/request-utility";
import { getToolTipStaffCardDescription } from "../../../utilities/get-toolTip-description-for-staff-card";

const WorkSkillsOverview = (props) => {
  const {
    workSkills,
    rating,
    id,
    nrOfCancelledShifts,
    has_driver_license,
    owns_car,
    description,
    nrOfShifts,
  } = props.shift.employee;
  const work_skills = workSkills.sort((a,b) => a.workSkill_id - b.workSkill_id);
  const [showDescription, setShowDescription] = useState(false);
  const [showCancellationReason, setShowCancellationReason] = useState(true);

  return (
    <div className={componentStyle(colors)}>
      <hr />
      <div className="card-container">
        <div className="rating-container">
          <div
            className="rating"
            onClick={() =>
              props.showModal({
                content: (
                  <PastEmployeeRatings
                    averageRating={rating.rating}
                    jobsTaken={rating.nrOfRatings}
                    employeeId={id}
                  />
                ),
              })
            }
          >
            <p>{rating.rating}</p>
            <div className="stars">
              <ReactStars
                count={5}
                edit={false}
                value={+rating.rating}
                size={16}
                color2={"#ffd700"}
              />

              <p>{`Jobs i alt: ${nrOfShifts ? nrOfShifts : 0}`}</p>
            </div>
          </div>

          {nrOfCancelledShifts > 0 && (
            <div
              onClick={() =>
                props.showModal({
                  content: <CancelledEmployeeShifts employeeId={id} />,
                })
              }
              className="cancelled-container"
            >
              <p>{`Aflysninger: ${nrOfCancelledShifts}`}</p>
            </div>
          )}
          <div className="driver-options">
            {props.shift.hiredBeforeCount != null &&
              props.shift.hiredBeforeCount > 0 && (
                <p className="employed-before">
                  {props.shift.hiredBeforeCount === 1
                    ? `Taget ${props.shift.hiredBeforeCount} job hos jer`
                    : `Taget ${props.shift.hiredBeforeCount} job hos jer`}
                </p>
              )}
            <p>
              Kørekort{" "}
              {has_driver_license ? (
                <CheckIcon style={{ color: colors.green }} />
              ) : (
                <CloseIcon style={{ color: colors.red }} />
              )}
            </p>
            <p>
              Egen bil{" "}
              {owns_car ? (
                <CheckIcon style={{ color: colors.green }} />
              ) : (
                <CloseIcon style={{ color: colors.red }} />
              )}
            </p>
          </div>
        </div>

        <div className="ratings-container">
          {work_skills.map((workSkill, index) => {
            return (
                <div className="outer-rating-container" key={workSkill?.workSkill_id}>
                  <div className="outer">
                    <img src={workSkill?.question_icon_url} style={{ height: "16px", width: "16px", borderRadius: "50%", display: workSkill.question_icon_url ? 'block' : 'none' }}/>
                    <p>{workSkill.title}</p>
                    <HelpCircleIcon
                      size={14}
                      data-tip
                      data-for={`question-employee-one-${id}-${workSkill?.workSkill_id}`}
                    />
                    <ReactTooltip
                      id={`question-employee-one-${id}-${workSkill?.workSkill_id}`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      {getToolTipStaffCardDescription(
                        workSkill.question,
                        workSkill.answer.text
                      )}
                    </ReactTooltip>
                  </div>
                  <CircularRating value={workSkill.answer.value} />
                </div>
            );
          })}

          <hr
            style={{
              width: "80%",
              color: colors.lightGrey,
            }}
          />
          {description.length > 50 ? (
            <Fragment>
              {showDescription ? (
                <Fragment>
                  <p className="description">{description}</p>
                  <button
                    onClick={() => setShowDescription(!showDescription)}
                    className="show-more-btn"
                  >
                    Skjul beskrivelse
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <p className="description">{`${description.substring(
                    0,
                    50
                  )}...`}</p>
                  <button
                    onClick={() => setShowDescription(!showDescription)}
                    className="show-more-btn"
                  >
                    Se fuld beskrivelse
                  </button>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <p className="description">{description}</p>
          )}
          {props.shift.removed_reason && (
            <Fragment>
              {showCancellationReason ? (
                <Fragment>
                  <p style={{ marginTop: "0.5rem" }} className="description">
                    {props.shift.removed_reason}
                  </p>
                  <button
                    onClick={() =>
                      setShowCancellationReason(!showCancellationReason)
                    }
                    className="cancel-reason"
                  >
                    Skjul begrundelse
                  </button>
                </Fragment>
              ) : (
                <button
                  onClick={() =>
                    setShowCancellationReason(!showCancellationReason)
                  }
                  className="cancel-reason"
                >
                  Se begrundelse for afmelding
                </button>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  div.cancelled-container {
    display: flex;
    justify-content: center;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    margin-top: 0.5rem;
    padding: 0.25rem 0;
    cursor: pointer;

    &:hover {
      background-color: ${colors.lightGrey};
    }

    p {
      font-size: 0.75rem;
    }
  }

  button.cancel-reason {
    margin-top: 0.5rem;
    background-color: ${colors.red};
    color: ${colors.white};
    border-radius: 5px;
    padding: 0.125rem 0.5rem;
    font-size: 0.6rem;
    font-weight: bold;
    outline: none;
  }

  button.cancel-reason:hover {
    background-color: ${colors.darkerRed};
  }

  hr {
    margin-top: 0.5rem;
  }
  div.card-container {
    display: flex;
    padding: 0.5rem 0;

    div.rating-container {
      min-width: 7rem;
      display: flex;
      flex-direction: column;

      div.rating,
      div.cancellation {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid ${colors.grey};
        border-radius: 5px;
        padding: 0.25rem 0.5rem;
        cursor: pointer;

        p {
          text-align: center;
          font-size: 0.85rem;
        }
      }

      div.rating {
        margin-bottom: 0.25rem;
      }

      div.rating:hover,
      div.cancellation:hover {
        background-color: ${colors.lightGrey};
        border-color: ${colors.grey};
      }
    }
  }

  div.stars {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ratings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p.description {
      font-size: 0.75rem;
      padding: 0.25rem;
      word-break: break-word;
    }

    .outer-rating-container {
      display: flex;
      align-items: center;
      width: 13.6rem;
      justify-content: space-between;
      margin-bottom: 0.25rem;

      div.outer {
        display: flex;
        flex: 1;

        svg {
          width: 1rem;
        }

        p {
          font-size: 0.55rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  div.driver-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem auto;

    p {
      font-size: 0.75rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 0.125rem;

      &.employed-before {
        text-align: center;
        font-size: 0.65rem;
        margin-bottom: 0.75rem;
      }

      svg {
        width: 1.15rem;
        height: 1.15rem;
      }
    }
  }

  button.show-more-btn {
    background-color: ${colors.lightGrey};
    border-radius: 5px;
    color: ${colors.black};
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    outline: none;
    border: 1px solid ${colors.mediumGrey};
  }
  button.show-more-btn:hover {
    background-color: ${colors.mediumGrey};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(null, mapDispatchToProps)(WorkSkillsOverview);
