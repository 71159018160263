// Libs
import React, { Fragment,useEffect } from "react";
import { css } from "emotion";
import { useHistory } from "react-router";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Spinner from "../../ui/Spinner";
import SkillsModal from "../editEmployee/SkillsModal";
import BookingOverview from "./BookingOverview";

// Styles
import { breakPoint } from "../../../styles/breakPoints";
import color_V1 from "../../../styles/colors-V-1.0";

// Utilities
import { doesWorkSkillExist } from "../../../utilities/check-if-workSkill-exists";

// Actions
import { hideModal, showModal } from "../../../actions/uiActions";
import { updateWorkSkill } from "../../../actions/authActions";
import { getStaffs } from "../../../actions/staffActions";

const UpcomingBookings = props => {
  const history = useHistory();

  useEffect(() => {
    props.getStaffs();
  }, []);

  // Redux state
  const { staffs } = useSelector((state) => state.staff);

  // Shows the selected booking
  const showBooking = ({ bookingId, staffId, staffType, employeeWorkSkills }) => {
    if (doesWorkSkillExist(staffId, employeeWorkSkills)) {
      // Redirect to the page
      history.push(`/bookinginformation/${bookingId}`);
    } else {
      let staffSkill = staffs.find((staff) => staff.job_title == staffType)
      props.showModal({
        hideTop: true,
        content: (
          <SkillsModal
            hideTop={true}
            update={workSkills => {
              props.updateWorkSkill(workSkills, () => {
                history.push(`/bookinginformation/${bookingId}`);
                props.hideModal();
              });
            }}
            skillId={staffSkill.id}
            skillTitle={staffSkill.job_title}
          />
        )
      });
    }
  };

  return (
    <div className={componentStyle(props)}>
      {props.loading && (
        <Spinner title={"Henter bookinger..."} style={{ marginTop: "2rem" }} />
      )}

      {!props.loading && props.bookings.length > 0 && (
        <Fragment>
          {props.bookings.map((booking, index) => (
            <div key={index}>
              <BookingOverview
                className="booking-overview"
                showBooking={showBooking}
                {...booking}
              />
              <div className="check-out-separate" />
            </div>
          ))}
        </Fragment>
      )}

      {!props.loading && props.bookings.length === 0 && (
        <div className="no-bookings-container">
          <p>Der er ingen kommende bookings i øjeblikket</p>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  .booking-overview {
    margin: 1rem 0;
  }

  div.check-out-separate {
    border-top: 1px solid ${color_V1.grey_10};
  }

  .no-bookings-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      align-items: center;
      font-weight: 500;
      margin-top: 1rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.75rem;
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  updateWorkSkill: bindActionCreators(updateWorkSkill, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  getStaffs: bindActionCreators(getStaffs, dispatch),
});

export default connect(null, mapDispatchToProps)(UpcomingBookings);
