// Libs
import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

// Utilities
import history from "./utilities/navigation-history";

// Styles
import "./styles/app.css";
import "./index.css";

// Components
import CorporationHome from "./components/corporation/CorporationHome";
import CorporationLogin from "./components/corporation/CorporationLogin";
import NewBooking from "./components/corporation/newBooking/NewBooking";
import NewCorporation from "./components/corporation/newCorporation/NewCorporation";
import ToastHandler from "./components/ui/ToastHandler";
import { bindActionCreators } from "redux";
import { autoSignIn } from "./actions/authActions";
import LoadingScreen from "./components/ui/LoadingScreen";
import ViewBooking from "./components/corporation/viewBooking/ViewBooking";
import EmployeeLogin from "./components/employee/EmployeeLogin";
import EmployeeHome from "./components/employee/EmployeeHome";
import NewUserForm from "./components/employee/newUserForm/NewUserForm";
import EditEmployee from "./components/employee/editEmployee/EditEmployee";
import BookingInformation from "./components/employee/applyForBooking/BookingInformation";
import EditCorporation from "./components/corporation/editCorporation/EditCorporation";
import EditBooking from "./components/corporation/editBooking/EditBooking";
import ShowEmployees from "./components/admin/ShowEmployees";
import ShowCorporations from "./components/admin/ShowCorporations";
import ReviewBooking from "./components/admin/approveBooking/ReviewBooking";
import ApproveBookingOverview from "./components/admin/approveBooking/ApproveBookingOverview";
import CheckOutEmployees from "./components/corporation/checkout/CheckOutEmployees";
import OldBookings from "./components/admin/OldBookings";
import BookingInvoice from "./components/admin/BookingInvoice";
import ResetPassword from "./components/corporation/ResetPassword";
import ConfirmResetPassword from "./components/corporation/ConfirmResetPassword";
import UpcomingBookings from "./components/admin/UpcomingBookings";
import UnsubscribeEmail from "./components/employee/UnsubscribeEmail";
import CheckOutEmployee from "./components/employee/shiftsOverview/CheckOutEmployee";
import StaffSkills from "./components/admin/StaffSkills";

const App = props => {
  useEffect(() => {
    props.autoSignIn();
    // eslint-disable-next-line
  }, []);

  const { auth, appConfig } = props;

  if (appConfig.loadingScreen) {
    return <LoadingScreen />;
  } else if (
    !auth.isAuthenticatedCorporation &&
    !auth.isAuthenticatedEmployee
  ) {
    return (
      <div>
        <ToastProvider>
          <Router history={history}>
            <Switch>
              <Route path="/email/unsubscribe/:token" component={UnsubscribeEmail}/>
              <Route path="/newcorporation" component={NewCorporation} />
              <Route path="/employeelogin" component={EmployeeLogin} />
              <Route path="/resetPassword" component={ResetPassword} />
              <Route path="/editemployee" component={EmployeeLogin} />
              <Route
                path="/https://app.jobbutler.net/bookinginformation/:id"
                component={EmployeeLogin}
              />
              <Route
                  path="/bookinginformation/:bookingId"
                  component={EmployeeLogin}
              />
              <Route
                  path="/employee/shifts/:bookingId"
                  component={EmployeeLogin}
              />

              <Route
                path="/confirmResetPassword/:token"
                component={ConfirmResetPassword}
              />
              <Route
                exact
                path="/corporationlogin"
                component={CorporationLogin}
              />

              <Route exact path="/" component={NewBooking} />
              <Route path="/checkout-employees/:bookingId" render={() => <Redirect to="/corporationlogin" />} />
              <Redirect to="/" />
            </Switch>
          </Router>
          <ToastHandler />
        </ToastProvider>
      </div>
    );
  } else if (auth.isAuthenticatedCorporation) {
    return (
      <div>
        <ToastProvider>
          <Router history={history}>
            <Switch>
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/showEmployees"
                  component={ShowEmployees}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/bookingInvoice/:bookingId"
                  component={BookingInvoice}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/oldBookings"
                  component={() => <OldBookings />}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/upcomingBookings"
                  component={() => <UpcomingBookings />}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/review-booking/:bookingId"
                  component={ReviewBooking}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/approve-booking-overview"
                  component={ApproveBookingOverview}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/showCorporations"
                  component={ShowCorporations}
                />
              )}
              {auth.user.is_admin && (
                <Route
                  exact
                  path="/admin/staffType"
                  component={StaffSkills}
                />
              )}
              <Route
                exact
                path="/checkout-employees/:bookingId"
                component={CheckOutEmployees}
              />
              <Route exact path="/newbooking" key={3} component={NewBooking} />
              <Route
                exact
                path="/editcorporation"
                component={EditCorporation}
              />
              <Route
                exact
                path="/viewBooking/:bookingId"
                component={ViewBooking}
              />
              <Route
                exact
                path="/editBooking/:bookingId"
                component={EditBooking}
              />
              <Route exact path="/" component={CorporationHome} />
              <Redirect to="/" />
            </Switch>
          </Router>
          <ToastHandler />
        </ToastProvider>
      </div>
    );
  } else if (auth.isAuthenticatedEmployee) {
    return (
      <div>
        <ToastProvider>
          <Router history={history}>
            <Switch>
              <Route path="/email/unsubscribe/:token" component={UnsubscribeEmail}/>
              {auth.user.new_user && (
                <Route path="/" key={4} component={NewUserForm} />
              )}
              <Route
                path="/bookinginformation/:bookingId"
                component={BookingInformation}
              />
              <Route
                path="/employee/shifts/:bookingId"
                component={BookingInformation}
              />
              <Route
                exact
                path="/checkout-employees/:shiftId"
                component={CheckOutEmployee}
              />
              <Route path="/editemployee" component={EditEmployee} />

              <Route path="/" component={EmployeeHome} />
              <Redirect to="/" />
            </Switch>
          </Router>
          <ToastHandler />
        </ToastProvider>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  appConfig: state.appConfig,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  autoSignIn: bindActionCreators(autoSignIn, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
