// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Components
import Spinner from "../../ui/Spinner";
import ErrorBox from "../../ui/ErrorBox";
import DateViewer from "../../ui/DateViewer";

// Utilities
import req from "../../../utilities/request-utility";
import { workHours } from "../../../utilities/get-work-hours-total";

// Actions
import { addToast } from "../../../actions/uiActions";

// Styles
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import colors from "../../../styles/colors";
import SingleImageContainer from "../../ui/SingleImageContainer";
import moment from "moment";
import ReactStars from "react-stars";

const ViewDeprecatedBooking = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [booking, setBooking] = useState({});

  // Get the booking
  useEffect(() => {
    req()(`corporations/bookings/${props.bookingId}/deprecated`)
      .then(({ data: booking }) => {
        setBooking(booking);
        setLoading(false);
      })
      .catch(() => {
        props.addToast({
          message: "Kunne ikke hente oplysningerne...",
          type: "error",
          autoDismiss: true
        });
        setError(true);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle()}>
      {loading && !error && (
        <Spinner style={{}} title={"Indlæser booking..."} />
      )}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <div className={componentStyle()}>
          {/* Section 1*/}
          <div className="section-one">
            <div className="date-container">
              <DateViewer
                outerTextSize={{ fontSize: "1.5rem" }}
                middleTextSize={{ fontSize: "1.5rem" }}
                date={booking.date}
                showYear={true}
              />
            </div>

            <div className="upper-description">
              <p className="arrangement-title">{booking.arrangement_type}</p>
              <div className="price-section">
                <div>
                  <div className="time-box">
                    <p className="sub-title">{`${booking.start_time} - ${
                      booking.end_time
                    } (${workHours({
                      start_time: booking.start_time,
                      end_time: booking.end_time
                    })} timer)`}</p>
                    {/*<p>{"+ 1 time"}</p>*/}
                    {/*<p>TRANSPORT TILLÆG</p>*/}
                  </div>
                  <div className="time-box">
                    <p className="sub-title">
                      {`${booking.hourly_wage}/t DKK`}
                    </p>
                  </div>
                  <div className="address-box">
                    <MapMarkerIcon size={20} />
                    <p>{`${booking.address}, ${booking.zip_code} ${booking.city}`}</p>
                  </div>
                </div>
                <div className="need-staff-container">
                  <p>Valgt</p>
                  <p>
                    {`${
                      booking.number_of_staff
                    } ${booking.staff_type.toUpperCase()}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Section 2 INFORMATION */}
          <div className="section-two">
            <div className="info-title-box">
              <p className="text-xl font-hairline text-gray-600">Info</p>
            </div>
            <div className="middle-section">
              <div className="outer-description-container">
                <div className="description-container">
                  <p>Uddybende jobbeskrivelse</p>
                  <p>{booking.job_description}</p>
                </div>
                <div className="access-information-container">
                  <p>{`Hvordan kommer jeg ind?`}</p>
                  <p>{booking.access_information}</p>
                </div>
              </div>
            </div>
          </div>
          {/* Section 3 EMPLOYEES */}
          <div className="section-three">
            <p className="title">{`Booket personale: ${booking.shifts.length}`}</p>
            {booking.shifts.map(
              ({
                employee,
                corporation_checkin_time,
                corporation_checkout_time
              }) => (
                <div className="chosen-staff">
                  <div className="staff-section-one">
                    <SingleImageContainer
                      imageStyle={{ height: "6rem", width: "6rem" }}
                      baseURL={employee.baseURL_image}
                      cloudinaryString={
                        "b_rgb:ffffff,c_pad,h_160,w_160/dpr_2.0,f_auto"
                      }
                      image={employee.profile_image}
                    />
                    <div className="wrapper">
                      <p>
                        {`Antal ratings `}
                        <span style={{ fontWeight: "bold" }}>
                          {employee.rating.nrOfRatings || 0}
                        </span>
                      </p>
                      <p>
                        {`Jobs taget: `}
                        <span style={{ fontWeight: "bold" }}>
                          {employee.nrOfCancelledShifts || 0}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="staff-section-two">
                    <div className="name-container">
                      <p>{`${employee.first_name} ${employee.last_name &&
                        employee.last_name.substring(0, 1).toUpperCase()}`}</p>
                      <p>{employee.age ? `${employee.age} år` : ""}</p>
                    </div>
                    <hr />
                    <div className="rating-container">
                      <p>PERFORMANCE</p>
                      <ReactStars
                        count={5}
                        value={parseFloat(employee.rating.rating) || 0}
                        half={false}
                        edit={false}
                        size={27}
                      />
                    </div>
                    <hr />
                    <div className="submitted-container">
                      <p>{`Du har registreret at ${employee.first_name} var der:`}</p>
                      <p>
                        {`${moment(booking.date)
                          .format("dddd")
                          .slice(0, 3)} ${moment(booking.date).format(
                          "DD/MM"
                        )} ${moment(corporation_checkin_time).format(
                          "hh:mm"
                        )} - ${moment(corporation_checkout_time).format(
                          "hh:mm"
                        )}`}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  min-height: 8rem;
  height: 100%;
  justify-content: center;

  hr {
    color: ${colors.lightGrey};
  }

  .submitted-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;

    p {
      font-size: 0.75rem;
    }

    p:last-of-type {
      font-weight: bold;
    }
  }

  .name-container {
    display: flex;
    align-items: baseline;
    padding-bottom: 0.25rem;

    p:first-of-type {
      font-size: 1.25rem;
      font-weight: bold;
      margin-right: 0.5rem;
    }

    p:last-of-type {
      font-size: 0.75rem;
    }
  }

  .rating-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;

    p {
      font-size: 0.85rem;
      color: ${colors.primeBlue};
    }
  }

  .section-one {
    display: flex;
    margin-bottom: 1rem;

    .date-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 5.2rem;
    }

    .upper-description {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      .arrangement-title {
        font-size: 1.25rem;
        padding: 0 0.5rem;
      }

      .price-section {
        display: flex;
        justify-content: space-between;
        background-color: ${colors.white};
        padding: 0.5rem;

        .time-box {
          display: flex;
          align-items: baseline;
          margin-bottom: 0.25rem;
        }

        .address-box {
          display: flex;
          align-items: center;
        }
        p {
          font-size: 0.875rem;
          margin-right: 0.5rem;
        }

        .sub-title {
          font-size: 0.85rem;
        }

        .need-staff-container {
          display: flex;
          flex-direction: column;
          justify-content: end;

          p:first-child {
            color: ${colors.darkGrey};
            font-size: 1rem;
          }
        }
      }
    }
  }

  .info-title-box {
    display: flex;
    justify-content: center;
    width: 5.2rem;

    p {
      font-size: 1.25rem;
      font-weight: 100;
      color: ${colors.darkGrey};
    }
  }

  .section-two {
    display: flex;

    .middle-section {
      display: flex;
      justify-content: space-between;
      background-color: ${colors.white};
      padding: 0.5rem;
      width: 100%;

      .description-container,
      .access-information-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        p:first-child {
          font-size: 0.85rem;
          font-weight: 600;
        }

        p:last-child {
          font-size: 0.85rem;
          overflow-wrap: break-word;
        }
      }

      .access-information-container {
        height: 5.2rem;
      }

      .required-stuff-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .required-stuff-container-row {
          display: flex;
          margin-bottom: 0.5rem;

          div:first-child {
            width: 7rem;

            p {
              font-size: 0.65rem;
              color: ${colors.darkerGrey};
              font-weight: 600;
            }
          }

          div:last-child {
            flex: 1;
            p {
              font-size: 0.65rem;
              overflow-wrap: break-word;
            }
          }
        }
      }
    }
  }

  .section-three {
    display: flex;
    flex-direction: column;
    background-color: ${colors.lightGreen};
    padding: 1rem;

    p.title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    div.chosen-staff {
      display: flex;
      background-color: ${colors.white};
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      margin-bottom: 1rem;
      padding: 1rem;

      div.staff-section-one {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
        justify-content: space-between;

        div.wrapper {
          padding: 0.5rem 0;
          p {
            font-size: 0.75rem;
          }
        }
      }

      div.staff-section-two {
        display: flex;
        flex: 2;
        flex-direction: column;
      }
    }
  }

  table {
    width: 100%;

    td {
      font-size: 0.7rem;
      padding-left: 0.5rem;
      padding-bottom: 0.25rem;
    }

    .separator {
      height: 2rem;
    }

    th {
      font-size: 0.65rem;
      text-align: left;
      padding-bottom: 0.25rem;
      font-weight: 600;
    }

    th:first-child {
      width: 15rem;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ViewDeprecatedBooking);
