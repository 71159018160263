// Libs
import React, { Fragment, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";

// Components
import SkillsModal from "./SkillsModal";

// Actions
import { deleteWorkSkill, updateWorkSkill } from "../../../actions/authActions";
import { hideModal, showModal } from "../../../actions/uiActions";
import { getStaffs } from "../../../actions/staffActions";


// Styles
import colors from "../../../styles/colors";
import WarningSkillsModal from "./WarningSkillsModal";
import Spinner from "../../ui/Spinner";
import LockIcon from "mdi-react/LockIcon";

const Skills = (props) => {
  const { jobRoles } = useSelector((state) => state.auth.user);

  const { loadingWorkSkill: loading } = useSelector((state) => state.auth.user);

  useEffect(() => {
    props.getStaffs();
  }, []);

  // Redux state
  const { staffs } = useSelector((state) => state.staff);

  const update = (skillNumber) => {
    props.updateWorkSkill(skillNumber, () => {
      props.hideModal();
    });
  };

  const deleteSkill = (skillNumber) => {
    props.deleteWorkSkill(skillNumber, () => {
      props.hideModal();
    });
  };

  return (
    <div className={componentStyle()}>
      {loading && <Spinner title={"Indlæser ændringer..."} />}
      {!loading && (
        <Fragment>
          <p className="types-of-jobs-title">TYPER AF JOBS</p>
          <p className="header-title">Hvilke typer jobs søger du? </p>
          <p className="sub-header-title">
            Vi stiller dig 5 spørgsmål til hver stilling, som du vælger
          </p>

          <div className="staff-type-container">
            {staffs &&
              staffs.map((staff) => (
                <div className="job-container">
                  <label>
                    <div className="last-container">
                      <input
                        checked={jobRoles.some(
                          (j) => j.employeeJobRole.jobRole_id === staff.id
                        )}
                        type="checkbox"
                        disabled={!staff.worker}
                        onClick={
                          jobRoles.some(
                            (j) => j.employeeJobRole.jobRole_id === staff.id
                          )
                            ? () =>
                                props.showModal({
                                  hideTop: true,
                                  content: (
                                    <WarningSkillsModal
                                      jobType={staff.id}
                                      skillTitle={staff.job_title}
                                      onDelete={() => deleteSkill(staff.id)}
                                    />
                                  ),
                                })
                            : () => {
                                props.showModal({
                                  content: (
                                    <SkillsModal
                                      update={update}
                                      skillId={staff.id}
                                      skillTitle={staff.job_title}
                                    />
                                  ),
                                });
                              }
                        }
                      />
                    </div>
                    <span className={!staff.worker ? "skill-disable": ""} >{staff.job_title}</span>
                      {!staff.worker && (<LockIcon/>)}
                  </label>
                </div>
              ))}
          </div>
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = () => css`
  padding: 0.5rem 1.5rem;

  p.types-of-jobs-title {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }

  p.header-title {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  p.sub-header-title {
    font-size: 0.75rem;
  }

  div.staff-type-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-flow: row wrap;

    div.job-container {
      display: flex;
      margin-top: 1.5rem;
      flex-basis: 33.3333%;

      @media screen and (max-width: 576px) {
        flex-basis: 50%;
      }

      @media screen and (max-width: 399px) {
        flex-basis: 100%;
      }

      label {
        display: flex;
        align-items: center;

        span {
          margin-left: 1rem;
          padding-top: 0.25rem;
          font-weight: bold;
          color: ${colors.darkestGrey};
        }

        div.last-container {
          padding: 0.5rem;
          display: flex;
          height: 2.5rem;
          align-items: center;
          width: 2.5rem;
          justify-content: center;
          background-color: ${colors.lightGrey};
          border: 1px solid ${colors.darkestGrey};
          cursor: pointer;

          input {
            cursor: pointer;
          }
        }

        div.last-container:hover {
          background-color: ${colors.grey};
        }
      }

      .skill-disable {
        color: #00000078;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  getStaffs: bindActionCreators(getStaffs, dispatch),
  deleteWorkSkill: bindActionCreators(deleteWorkSkill, dispatch),
  updateWorkSkill: bindActionCreators(updateWorkSkill, dispatch),
});

export default connect(null, mapDispatchToProps)(Skills);
