// Modules
import React, {useEffect, useState} from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Styles
import colors from "../../../styles/colors";
import { breakPoint } from "../../../styles/breakPoints";
import { workHours } from "../../../utilities/get-work-hours-total";
import { addCommas } from "../../../utilities/add_commas";
import {
  bookingFee
} from "../../../utilities/calculate-booking-fee";
import { calculateFee } from "../../../utilities/calculate-booking-fee";
import { remove_commas } from "../../../utilities/remove_commas";

const MiddleSection = (props) => {
  const { booking, setBooking } = props;
  const [booking_fee, setBookingFees] = useState({});
  const [booking_fee_obj, setBookingFeeObj] = useState({});
  const { takenOverCorporation, user } = useSelector(
    (state) => state.auth
  );

  useEffect(()=>{
    bookingFeeObjFetch();
  }, [booking])
  useEffect(() => {
    bookingFeeFetch();
  }, [])
  const bookingFeeFetch = async() => {
    let {standard_fee, urgentBookingFee, emergencyBookingFee} = await bookingFee(booking.staff_type, {standard_fee: user.standard_fee, urgent_booking_fee: user.urgent_booking_fee, emergency_booking_fee: user.emergency_booking_fee});
    const [sf, ubf, ebf] = [parseFloat(standard_fee), parseFloat(urgentBookingFee), parseFloat(emergencyBookingFee)]
    setBookingFees({standard_fee: sf, urgentBookingFee:ubf, emergencyBookingFee: ebf})
    setBooking({...booking, standard_fee: sf, urgent_booking_fee: ubf, emergency_booking_fee: ebf})
  }
  const bookingFeeObjFetch = async() => {
    let booking_fee_obj = booking;
    if(!booking.fee){
      booking_fee_obj = await calculateFee(booking.date, booking)
    }
    setBookingFeeObj(booking_fee_obj)
  }

  const {start_time, end_time, hourly_wage, transport_wage, number_of_staff} = booking
  const staff_arr = new Array(parseInt(number_of_staff)).fill(null);
  let workingHours = workHours({start_time, end_time});
  let ferie = remove_commas(hourly_wage) * .125;
  const unit_total = parseFloat(remove_commas(hourly_wage)) + parseFloat(ferie)
  const total = unit_total * parseFloat(workingHours);
  const total_booking_fee = booking_fee_obj.fee;
  console.log({total_booking_fee})
  let standard_fee = 0;
  let urgent_booking_fee = 0;
  let emergency_booking_fee = 0;
  if(booking.date){
    standard_fee = booking_fee.standard_fee;
  }
  if(booking.date && total_booking_fee > booking_fee.standard_fee){
    urgent_booking_fee = booking_fee.urgentBookingFee;
  }
  if(booking.date && total_booking_fee > booking_fee.standard_fee+booking_fee.urgentBookingFee){
    emergency_booking_fee = booking_fee.emergencyBookingFee;
  }
  let total_working_hours = parseFloat(workingHours*number_of_staff)
  let transport_hours = transport_wage*number_of_staff
  total_working_hours += parseFloat(transport_hours);
  let total_transport = parseFloat(remove_commas(hourly_wage))+parseFloat(ferie);
  let grand_total_transport = total_transport * transport_hours;
  const grand_total = parseFloat(total*number_of_staff) + parseFloat(grand_total_transport)

  return (
    <div className={componentStyle(colors)}>
      <div className="third-section flex-row">
        {(user.is_admin || takenOverCorporation) && (
          <div className="admin-message">
            <label>Admin message</label>
            <textarea
              name="admin_message"
              value={booking.admin_message}
              onChange={(e) => {
                setBooking({ ...booking, [e.target.name]: e.target.value });
              }}
              rows="5"
            />
          </div>
        )}
        <div className="note-faktura">
          <label>Note til faktura</label>
          <input
            placeholder="Tilføjes faktura"
            name="invoice_note"
            value={booking.invoice_note}
            onChange={(e) =>
              setBooking({
                ...booking,
                [e.target.name]: e.target.value,
              })
            }
          />
          <label className="payment-deadline">Betalingsfrist : {`${user.payment_deadline} dage`}</label>
        </div>
      </div>
      <label style={{fontSize: '12px'}}>Pris forspørgsel</label>
      <div className="expanded-content" onClick={(e) => e.stopPropagation()}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Antal</th>
              <th>Pr. time</th>
              <th>Ferie</th>
              <th>Enhedspris</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              staff_arr?.map((staff, index) => {
                return(<tr key={index}>
                  <td className="left">{index + 1}. {`${start_time} - ${end_time}`}</td>
                  <td>{addCommas(parseFloat(workingHours)?.toFixed(2))}</td>
                  <td>{addCommas(hourly_wage)}</td>
                  <td>{addCommas(parseFloat(ferie)?.toFixed(2))}</td>
                   <td>{addCommas(parseFloat(unit_total)?.toFixed(2))}</td>
                 <td>{addCommas(parseFloat(total)?.toFixed(2))}</td>
                </tr>)
              })
            }

            {
              parseFloat(transport_wage) > 0 ? (
                <tr>
                  <td className="left">Transporttillæg</td>
                  <td>{addCommas(parseFloat(transport_hours)?.toFixed(2))}</td>
                  <td>{addCommas(parseFloat(remove_commas(hourly_wage))?.toFixed(2))}</td>
                  <td>{addCommas(parseFloat(ferie)?.toFixed(2))}</td>
                  <td>{addCommas(parseFloat(total_transport)?.toFixed(2))}</td>
                  <td>{addCommas(parseFloat(grand_total_transport)?.toFixed(2))}</td>
                </tr>
              ) : <></>
            }
            <tr>
              <td colSpan={6}> &nbsp;</td>
            </tr>
            <tr>
              <td className="left pad-left">Service Tillæg</td>
              <td>{addCommas(parseFloat(total_working_hours)?.toFixed(2))}</td>
              <td>{standard_fee ? addCommas(parseFloat(standard_fee)?.toFixed(2)) : '-'}</td>
              <td></td>
              <td>{standard_fee ? addCommas(parseFloat((remove_commas(standard_fee) * (parseFloat(workingHours))))?.toFixed(2)) : '-'}</td>
              <td>{standard_fee ? addCommas(parseFloat((remove_commas(standard_fee) * (parseFloat(total_working_hours))))?.toFixed(2)) : '-'}</td>
            </tr>
            {
              urgent_booking_fee ? (
                <tr>
                  <td className="left pad-left">{`Hastebooking (<48 timer)`}</td>
                  <td>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</td>
                  <td>{addCommas(parseFloat(urgent_booking_fee)?.toFixed(2))}</td>
                  <td></td>
                  <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(urgent_booking_fee) * (parseFloat(workingHours))))?.toFixed(2)) : '-'}</td>
                  <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(urgent_booking_fee) * (parseFloat(total_working_hours))))?.toFixed(2)) : '-'}</td>
                </tr>
              ) : <></>
            }
            {
              emergency_booking_fee ? (
                <tr>
                  <td className="left pad-left">{`Akutbooking (<24 timer)`}</td>
                  <td>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</td>
                  <td>{addCommas(parseFloat(emergency_booking_fee)?.toFixed(2))}</td>
                  <td></td>
                  <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(emergency_booking_fee) * (parseFloat(workingHours))))?.toFixed(2)) : '-'}</td>
                  <td>{parseFloat(total_working_hours) ? addCommas(parseFloat((remove_commas(emergency_booking_fee) * (parseFloat(total_working_hours))))?.toFixed(2)) : '-'}</td>
                </tr>
              ) : <></>
            }
            <tr>
              <td colSpan={6}> &nbsp;</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th className="left">Total eks. moms DKK.</th>
              <th>{parseFloat(total_working_hours) ? addCommas(parseFloat(total_working_hours)?.toFixed(2)) : '-'}</th>
              <th>{booking.date ? addCommas((parseFloat(remove_commas(hourly_wage)) + parseFloat(ferie) + parseFloat(standard_fee) + parseFloat(urgent_booking_fee) + parseFloat(emergency_booking_fee))?.toFixed(2)) : '-'}</th>
              <th></th>
              <th></th>
              <th>{booking.date && parseFloat(total_working_hours) ? addCommas(parseFloat(grand_total + ((parseFloat(standard_fee) + parseFloat(urgent_booking_fee) + parseFloat(emergency_booking_fee)) * total_working_hours))?.toFixed(2)) : '-'}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
  .expanded-content {
    background: #fff;
    border: 1px solid;
    padding: 10px 20px;
    border-radius: 10px;
    color: #4A4A4A;
    table{
      width: 100%;
      font-size: 12px;
      tbody {
        text-align: center;
      }
      .left{
        text-align: left;
      }
      .pad-left {
        padding-left: 13px;
      }
    }
  }
  .third-section {
    display: flex;
    position: relative;
    flex-direction: column;

    &.flex-row {
      display: flex;
      justify-content: flex-end;
      margin: 30px 0;
      flex-direction: row;
      align-items: flex-start;
      label {
        font-size: 12px !important;
      }
      .admin-message {
        display: flex;
        flex-direction: column;
        flex: 0 0 50%;
        max-width: 50%;
        textarea {
          border: 1px solid #707070;
          border-radius: 5px;
        }
      }
      .note-faktura {
        margin: 0 0 0 10px;
        align-self: flex-start;
      }
      .payment-deadline {
        margin-top: 10px;
      }
    }

    .note-faktura {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin: 0.6rem 0;
      align-self: flex-end;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 100%;
        margin: 0;
      }

    }
    label {
      letter-spacing: 0.025em;
      color: ${colors.black};
      font-size: 12px;
      margin-bottom: 0.25rem;
      margin-left: 0.25rem;
    }
    input,
    textarea {
      cursor: text;
      display: block;
      font-size: 0.75rem;
      outline: none;
      width: 100%;
      background-color: ${colors.white};
      border: 1px solid ${colors.darkGrey};
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      line-height: 1.25;
    }

    input:hover,textarea:hover {
      border: 1px solid ${colors.black};
    }

    input::placeholder, textarea::placeholder {
      color: ${colors.placeholder};
    }
  }
`;

export default MiddleSection;
