import React from "react";
import colors from "../styles/colors";

export const getToolTipStaffCardDescription = (question,answer) => {
  let skillQuestion = question
  let skillAnswer = answer
  return (
    <div>
      <p
        style={{
          fontSize: "0.65rem",
          fontWeight: "bold",
          padding: `0.5rem 1rem`,
          marginBottom: "0.2rem",
          maxWidth: "16rem"
        }}
      >
        {skillQuestion}
      </p>
      <p
        style={{
          fontSize: "0.65rem",
          backgroundColor: colors.white,
          color: colors.black,
          fontWeight: "bold",
          padding: `0.5rem 1rem`,
          borderRadius: "3px"
        }}
      >
        {skillAnswer}
      </p>
    </div>
  );
}
