//libs 
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import FileSaver from "file-saver";
import { parse } from "json2csv";

// Styles
import { css } from "emotion";

//Action
import { hideModal, addToast } from "../../actions/uiActions";

//utilities
import req from "../../utilities/request-utility";

const CorporationCvr = props => {

    const setLoadingDownloadCorporationCSV = props.setLoadingDownloadCorporationCSV

    //local state
    const verificationPassword = 'Nokia1728'
    const [cvrPassword,setCvrPasswork] = useState('')

    const handleVerification = async () => {
        if (cvrPassword && cvrPassword === verificationPassword) {
          setLoadingDownloadCorporationCSV(true)
          props.hideModal()
          
          const response = await req()('admin/corporations/corporations-csv')
          const corporationsData = response.data

          const corporationDetails = [];

          corporationsData.forEach((corporationData) => {
            const corporation = corporationData.corporationDetails;
            const createdDate = moment(corporation.terms_accepted_at).format("DD-MM-YYYY")
            corporationDetails.push({
              ID: corporation.id,
              Firmanavn: corporation.name,
              Cvr: corporation.cvr_nr,
              Email: corporation.email,
              Telefon: corporation.phone,
              OPrettet: createdDate,
              NumberOfBooking: corporationData.totalBookings,
              Kontakt_person: corporation.contact_person,
              MobilNr: corporation.phone
            })
          })

          if (corporationDetails.length === 0) {
            setLoadingDownloadCorporationCSV(false)
            props.addToast({
              message:
                "Kunne ikke finde noget firma",
              type: "error",
              autoDismiss: true,
            });
            return;
          }

          const options = { delimiter: ";" };

          let csv = parse(corporationDetails, options);
          let BOM = "\uFEFF";
          csv = BOM + csv;
          const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          FileSaver.saveAs(csvData, "data.csv");
          setLoadingDownloadCorporationCSV(false)

        }else{
            props.addToast({
                message:
                "Indtast korrekt adgangskode for at downloade cvr",
                type: "error",
                autoDismiss: true,
            });
            props.hideModal()
        }
    }

    return (
        <div className={componentStyle()}>
            <input placeholder="password..." value={cvrPassword} onChange={(e) => setCvrPasswork(e.target.value)}></input>
            <div className="verification-btn">
                <button onClick={handleVerification}>Verificere</button>
            </div>
        </div>
    )
}

const componentStyle = () => css`
    width: 450px;
    height: 140px;
    padding: 0 12px;
    padding-top: 2rem;

    input {
        width: 100%;
        border: 1px solid gray;
        border-radius: 5px;
        outline-color: gray;
        height: 40px;
        margin-bottom: 15px;
        padding: 10px;
    }

    .verification-btn{
        display: flex;
        flex-direction: row-reverse;

        button{
            border: 1px solid gray;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            background-color: #3c75c9;
            color:#fff;
            font-weight:500;
        }
    }

`;

const mapDispatchToProps = dispatch => ({
    hideModal: bindActionCreators(hideModal, dispatch),
    addToast: bindActionCreators(addToast, dispatch)
});
  
export default connect(null, mapDispatchToProps)(CorporationCvr);