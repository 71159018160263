// Libs
import React, {  useEffect, useState } from "react";
import { css } from "emotion";

// Components
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";

// Config
import {
  hours,
  fifteenMinutes
} from "../corporation/newBooking/config";
import userTypes from "../../config/userTypes";

// Styles
import colors from "../../styles/colors";

const ChangeTimeModal = props => {
  const { userType, shift, booking } = props;

  const [checkout, setCheckout] = useState({
    start_hours: "00",
    start_minutes: "00",
    end_hours: "00",
    end_minutes: "00",
    rating: 0,
    comment: ""
  });

  const { corporation_checkin_time, corporation_checkout_time, employee_checkin_time, employee_checkout_time, checkout_rejected_count_employee, checkout_rejected_count_corporation, last_checkout_by } = shift;

  const employeeCheckedOut = employee_checkin_time && employee_checkout_time;
  const corporationCheckedOut = corporation_checkin_time && corporation_checkout_time;

  const checkoutRejected = employeeCheckedOut && corporationCheckedOut && (corporation_checkin_time != employee_checkin_time || corporation_checkout_time != employee_checkout_time);
  const checkoutApproved = employeeCheckedOut && corporationCheckedOut && (corporation_checkin_time == employee_checkin_time && corporation_checkout_time == employee_checkout_time);

  const checkoutRejectedByCompany = checkoutRejected && last_checkout_by == "corporation"
  const checkoutApprovedByCompany = checkoutApproved && last_checkout_by == "corporation"
  const checkoutApprovedByEmployee = checkoutApproved && last_checkout_by == "employee"

  // const adminEmployeeCheckoutTimes = checkoutApprovedByCompany ? {start_time: shift.corporation_checkin_time, end_time : shift.corporation_checkout_time } : checkoutApprovedByEmployee ? {start_time: shift.employee_checkin_time, end_time : shift.employee_checkout_time } : shift.admin_employee_checkin_time && shift.admin_employee_checkout_time ? {start_time: shift.admin_employee_checkin_time, end_time : shift.admin_employee_checkout_time } : {start_time: booking.start_time, end_time : booking.end_time }
  const adminEmployeeCheckoutTimes = {
    start_time: shift.employee_checkin_time == shift.admin_employee_checkin_time ? shift.employee_checkin_time : shift.admin_employee_checkin_time,
    end_time: shift.employee_checkout_time == shift.admin_employee_checkout_time ? shift.employee_checkout_time : shift.admin_employee_checkout_time,
  };

  // const adminCompanyCheckoutTimes = checkoutApprovedByCompany ? {start_time: shift.corporation_checkin_time, end_time : shift.corporation_checkout_time } : checkoutApprovedByEmployee ? {start_time: shift.employee_checkin_time, end_time : shift.employee_checkout_time } : shift.admin_checkin_time && shift.admin_checkout_time ? {start_time: shift.admin_checkin_time, end_time : shift.admin_checkout_time } : {start_time: booking.start_time, end_time : booking.end_time }; 
  const adminCompanyCheckoutTimes = {
    start_time: shift.employee_checkin_time == shift.admin_checkin_time ? shift.employee_checkin_time : shift.admin_checkin_time,
    end_time: shift.employee_checkout_time == shift.admin_checkout_time ? shift.employee_checkout_time : shift.admin_checkout_time,
  };

  // ComponentDidMount() --> updates the local state to the known information
  useEffect(() => {
    if (
      userType === userTypes.corporation &&
      adminCompanyCheckoutTimes.start_time &&
      adminCompanyCheckoutTimes.end_time
    ) {
      setCheckout({
        ...checkout,
        start_hours: adminCompanyCheckoutTimes.start_time.split(":")[0],
        start_minutes: adminCompanyCheckoutTimes.start_time.split(":")[1],
        end_hours: adminCompanyCheckoutTimes.end_time.split(":")[0],
        end_minutes: adminCompanyCheckoutTimes.end_time.split(":")[1]
      });
    }
    if (
      userType === userTypes.employee &&
      adminEmployeeCheckoutTimes.start_time &&
      adminEmployeeCheckoutTimes.end_time
    ) {
      setCheckout({
        ...checkout,
        start_hours: adminEmployeeCheckoutTimes.start_time.split(":")[0],
        start_minutes: adminEmployeeCheckoutTimes.start_time.split(":")[1],
        end_hours: adminEmployeeCheckoutTimes.end_time.split(":")[0],
        end_minutes: adminEmployeeCheckoutTimes.end_time.split(":")[1]
      });
    }

    // eslint-disable-next-line
  }, []);

  // OnSubmit parse the checkout state to the onCorporationCheckout() and the employeeId
  const submit = () => {
    if (userType === userTypes.corporation) {
      const admin_checkin_time = `${checkout.start_hours}:${checkout.start_minutes}`;
      const admin_checkout_time = `${checkout.end_hours}:${checkout.end_minutes}`;
      props.onAdminCheckout( 
        {
          admin_checkin_time,
          admin_checkout_time,
        },
        shift.id
      );
    }
    if (userType === userTypes.employee) {
      const admin_employee_checkin_time = `${checkout.start_hours}:${checkout.start_minutes}`;
      const admin_employee_checkout_time = `${checkout.end_hours}:${checkout.end_minutes}`;

      props.onAdminCheckout(
        {
          admin_employee_checkin_time,
          admin_employee_checkout_time,
        },
        shift.id
      );
    }
  };

  return (
    <div className={componentStyle()}>
      {/* TID */}
      <div
        className="outer-container"
        style={{ justifyContent: "space-evenly" }}
      >
        {/* Timer */}
        <div className="time-container">
          <div style={{ position: "relative" }}>
            <select
              value={checkout.start_hours}
              name="start_hours"
              onChange={e =>
                setCheckout({ ...checkout, [e.target.name]: e.target.value })
              }
            >
              {hours.map((hour, index) => (
                <option key={index} value={hour.value}>
                  {hour.value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Minutter */}
        <div className="time-container">
          <select
            value={checkout.start_minutes}
            name="start_minutes"
            onChange={e =>
              setCheckout({ ...checkout, [e.target.name]: e.target.value })
            }
          >
            {fifteenMinutes.map((minutes, index) => (
              <option key={index} value={minutes.value}>
                {minutes.value}
              </option>
            ))}
          </select>
        </div>
        {/* Klokke Ikon */}
        <div className="colon">
          <ClockOutlineIcon />
        </div>

        {/* Timer */}
        <div className="time-container">
          <div style={{ position: "relative" }}>
            <select
              value={checkout.end_hours}
              name="end_hours"
              onChange={e =>
                setCheckout({ ...checkout, [e.target.name]: e.target.value })
              }
            >
              {hours.map((hour, index) => (
                <option key={index} value={hour.value}>
                  {hour.value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Minutter */}
        <div className="time-container">
          <select
            value={checkout.end_minutes}
            name="end_minutes"
            onChange={e =>
              setCheckout({ ...checkout, [e.target.name]: e.target.value })
            }
          >
            {fifteenMinutes.map((minutes, index) => (
              <option key={index} value={minutes.value}>
                {minutes.value}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button onClick={() => submit()} className="submit-btn">
        Opdater
      </button>
    </div>
  );
};

const componentStyle = () => css`
  padding: 0 0.35rem 0.35rem;

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }

  select {
    cursor: pointer;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 0.5rem;
    border-radius: 0.25rem;
    line-height: 1;
    outline: none;
  }
  select:hover {
    border: 1px solid ${colors.black};
  }

  select::placeholder {
    background-color: ${colors.grey};
  }

  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.25rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  textarea:hover {
    border: 1px solid ${colors.black};
  }

  textarea::placeholder {
    color: ${colors.darkerGrey};
  }

  .outer-container {
    display: flex;
    justify-content: start;
    margin-bottom: 0.5rem;
    align-items: center;
    min-width: 17rem;

    .staff-container {
      margin-right: 0.5rem;
    }

    .date-container {
      margin-right: 0.5rem;
    }

    .amount-staff-container {
      width: 3.3rem;
    }
  }

  .colon {
  }

  div.rating-container {
    div.rating {
      display: flex;
      justify-content: center;
    }
  }

  div.comment-container {
    margin-bottom: 1rem;
  }

  button.submit-btn {
    text-align: center;
    width: 100%;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    background-color: ${colors.lightBlue};
    color: ${colors.white};
    padding: 0.35rem 0rem;
    font-weight: bold;
    border-radius: 5px;
  }

  button.submit-btn:hover {
    background-color: ${colors.blue};
  }
`;

export default ChangeTimeModal;
