export function addStrings(string1, string2) {
    // Replace commas with periods and convert to numbers
    let number1 = parseFloat(string1.replace(",", "."));
    let number2 = parseFloat(string2.replace(",", "."));
  
    let result = number1 + number2;
  
    // Format as float with two decimal places
    let formattedResult = result.toFixed(2);
  
    return formattedResult;
  }
  