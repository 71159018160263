// Libs
import React, {useEffect} from "react";
import { useSelector, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Styles
import colors from "../../../styles/colors";
import LockIcon from "mdi-react/LockIcon";
import StepBar from "../../ui/StepBar";
import { breakPoint } from "../../../styles/breakPoints";

// Actions
import { getStaffs } from "../../../actions/staffActions";

const ChooseJobRoles = props => {
  const { userInformation, setUserInformation, displayTransferAccountPopup } = props;

  useEffect(() => {
    props.getStaffs();
  }, []);

  const { first_name } = useSelector(state => state.auth.user);

  // Redux state
  const { staffs} = useSelector((state) => state.staff);

  const addJobRole = id => {
    if (userInformation.jobRoles.some(role => role === id)) {
      const tempJobRoles = userInformation.jobRoles.filter(
        role => role !== id
      );
      setUserInformation({ ...userInformation, jobRoles: [...tempJobRoles] });
    } else {
      setUserInformation({
        ...userInformation,
        jobRoles: [...userInformation.jobRoles, id]
      });
    }
  };

  return (
    <div className={componentStyle()}>
      <div className="upper-container">
        <p className="title">{`Velkommen ${first_name}`}</p>
        <p className="sub-title">
          Udfyld spørgsmålene for at komme i gang med at søge arbejde!
        </p>
        <p className="sub-title">
          Har du en gammel profil, klik <span onClick={displayTransferAccountPopup} style={{textDecoration: 'underline'}}>her</span>!
        </p>
      </div>

      <div className="big-container">
        <p className="title">Hvilke type jobs søger du?</p>

        <div className="inside-container">
          {staffs?.map((staff) => (
            <button value={staff.job_title} className={props.userInformation.jobRoles.some(role => role === staff.id) ? 'selected' : 'not-selected' } 
            disabled={!staff.worker} onClick={e => addJobRole(staff.id)} >{staff.job_title}{!staff.worker && (<LockIcon/>)}</button>
          ))}
        </div>

        <button
          disabled={0 < !props.userInformation.jobRoles.length}
          onClick={() => props.moveForward()}
          className={
            props.userInformation.jobRoles.length > 0 ? "submit" : "disabled"
          }
        >
          VIDERE
        </button>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  height: 100%;
  div.upper-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p.title {
      margin-top: 3rem;
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
      font-weight: 500;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 1rem;
      }
    }

    p.sub-title {
      font-size: 0.75rem;
      text-align: center;
    }
  }

  div.big-container {
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.25rem;
    padding: 1rem 0.125rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      margin: 1.25rem 0 0;
    }

    p.title {
      font-size: 0.75rem;
      margin-bottom: 1rem;
    }

    div.inside-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      width: 100%;
      flex-wrap: wrap;
      margin: 0 -0.5rem;

      button.selected,
      button.not-selected,
      button.disabled {
        border: 1px solid ${colors.darkGrey};
        border-radius: 5px;
        font-size: 0.85rem;
        font-weight: 500;
        background-color: ${colors.lightGrey};
        outline: none;
        max-width: calc(50% - 16px);
        flex: 0 0 50%;
        margin: 0 0.5rem 1rem;
        padding: 1rem 0;
        display: flex;
        justify-content: center;


        @media screen and (max-width: ${breakPoint.sm}px) {
          padding: 0.5rem 0;
          font-size: 0.6rem;

          svg {
            height: 0.75rem;
            width: 0.75rem;
          }
        }
      }

      button.disabled {
        background-color: ${colors.disabledColor};
        color: ${colors.black};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 0.5rem;
          color: ${colors.darkerGrey};
        }
      }

      button.not-selected:hover {
        background-color: ${colors.grey};
      }

      button.selected {
        background-color: ${colors.primeBlue};
        color: ${colors.white};
      }

      button.selected:hover {
        background-color: ${colors.darkerPrimeBlue};
      }
    }
  }

  div.middle-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  button.submit,
  button.disabled {
    padding: 0.5rem 3rem;
    color: ${colors.white};
    border-radius: 5px;
    outline: none;
    background-color: ${colors.lightBlue};

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.85rem;
    }
  }

  button.submit:hover {
    background-color: ${colors.blue};
  }

  button.disabled {
    background-color: ${colors.grey};
    cursor: no-drop;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getStaffs: bindActionCreators(getStaffs, dispatch),
});

export default connect(null, mapDispatchToProps)(ChooseJobRoles);
