// Modules
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "emotion";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/da";

// Components
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import DateViewer from "../ui/DateViewer";
import ClipboardTextOutlineIcon from "mdi-react/ClipboardTextOutlineIcon";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import Spinner from "../ui/Spinner";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";

// Utilities and config
import { workHours } from "../../utilities/get-work-hours-total";
import { isBeforeBookingTime } from "../../utilities/check-booking-open";

// Styles
import colors from "../../styles/colors";
import bookingTypes from "../../config/bookingTypes";
import ViewDeprecatedBooking from "./viewDeprecatedBooking/ViewDeprecatedBooking";
import { bindActionCreators } from "redux";
import { showModal } from "../../actions/uiActions";
import { breakPoint } from "../../styles/breakPoints";
import englishFlag from "../../static/eng.png";
import danishFlag from "../../static/dan.png";
import req from "../../utilities/request-utility";

// Actions
import { addToast } from "../../actions/uiActions";
import DisplayNumber from "./DisplayNumber";

const Booking = (props) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [totalCheckedInEmployees, setTotalCheckedInEmployees] = useState(0);

  const { booking } = props;
  const dispatcher = useDispatch();

  useEffect(()=>{
    const selectedShifts = booking.shifts?.filter((shift) => shift.status === "selected");
    setSelectedEmployees(selectedShifts)
    let totalCheckedInEmployee = selectedShifts?.reduce((totalEmployee, employee) => totalEmployee + (employee.employee_checkin ? 1 : 0), 0) || 0
    setTotalCheckedInEmployees(totalCheckedInEmployee)
    if(booking.box_status){
      setExpanded(true);
    }
  }, [booking])
  const onSubmitHandler = () => {
    // if checkout approved by admin lock the booking
    if (booking.status === bookingTypes.deprecated) {
      props.showModal({
        content: <ViewDeprecatedBooking bookingId={booking.id} />,
      });
    } else if (booking.status === bookingTypes.finished) {
      history.push(`/checkout-employees/${booking.id}`);
    } else {
      history.push(`/viewBooking/${booking.id}`);
    }
  };
  const copySelectedEmployees = (e) => {
    e.stopPropagation();
    let employees = selectedEmployees.map((employee, index) => {
      let firstName = employee.employee.first_name || ``
      let lastName = employee.employee.last_name || ``;
      if(lastName){
        lastName = lastName.charAt(0).toUpperCase();
      }
      return `${index+1}. ${firstName} ${lastName}`
    }).join("\n");
    navigator.clipboard.writeText(employees);
    dispatcher(
      addToast({
        message:
          "kopieret med succes",
        type: "success",
        autoDismiss: true
      })
    );
  }
  const showMore = async(e) => {
    e.stopPropagation();
    setLoading(true)
    req()
    .put(`corporations/bookings/${booking.id}/change-booking-ui-status`, {box_status: !expanded})
    .then(() => {
      setExpanded((expanded) => !expanded)
      setLoading(false)  
    }) .catch(() => {
      setLoading(false);
    });
  }
  const calculateRemainingTime = (date) => {
    const currentDate = moment();
    const momentDate = moment(date); // create a new moment object from the date variable
    const diffInDays = momentDate.diff(currentDate, 'days');
  
    if (diffInDays < 0) {
      // If the given date is in the past
      return true;
    } else if (diffInDays <= 2) {
      // If less than or equal to 2 days remaining until the given date
      const twoDaysBefore = momentDate.clone().subtract(2, 'days').startOf('day').add(6, 'hours');
      if (currentDate >= twoDaysBefore) {
        // If the current time is after 6:00 am on the day that is 2 days before the given date
        return true;
      }
    }
    
    return false;
  };

  const { takenOverCorporation } = useSelector((state) => state.auth);
  const { is_admin } = useSelector((state) => state.auth.user);
  let checkInStarted = calculateRemainingTime(booking.date)
  let bookingHasTime = isBeforeBookingTime(booking.date, booking.start_time, 2)
  return (
    <div
      onClick={() => onSubmitHandler()}
      className={`${componentStyle(colors, loading, bookingHasTime)} ${props.className || ""}`}
      style={props.style}
    >
      <div className="inside-component">
        {props.waitingApproval && (
          <div
            style={{ position: "absolute", top: "-28px", fontWeight: "bold" }}
          >
            <p>{booking.corporation.name}</p>
          </div>
        )}

        <div className="date-container">
          <DateViewer date={booking.date} className="date-style" size={23} />
        </div>

        <div className="content-box">
          <div className="default-content">
            <div className="container-a">
            <p className="container-a-title"><ClipboardTextOutlineIcon size={20} className="icon-right-gap"/>{booking.arrangement_type ? booking.arrangement_type : booking.arrangement_type_other }</p>
              <div className="container-a-b">
              <div className="time-container">
                <ClockOutlineIcon size={20} className="icon-right-gap"/>
                  <p> {`${
                    booking.start_time
                  } - ${booking.end_time} [${workHours({
                    start_time: booking.start_time,
                    end_time: booking.end_time
                  })}/t]`}</p>
                </div>

                <div className="address-container">
                  <MapMarkerIcon size={20} className="icon-right-gap"/>
                  <p className="text-xs text-gray-600">
                    {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="container-b">
              <div className="container-a-b">
                  {!booking.approved && (
                    <>
                      <div class="label">
                        <p className="red-label">AFVENTER GODKENDELSE AF ADMIN</p>
                      </div>
                    </>
                  )}

                  {booking.approved &&
                  booking.selectedShifts !== booking.number_of_staff &&
                  booking.status !== bookingTypes.finished &&
                  booking.status !== "deprecated" && (
                    <div className="label-wrapper">
                      <p className="yellow-label">GODKENDT - SØGER</p>
                    </div>
                  )}
                  {booking.approved &&
                  booking.selectedShifts !== booking.number_of_staff &&
                  booking.status !== bookingTypes.finished &&
                  booking.status === "deprecated" && (
                    <>
                      <p className="green-label">Færdig</p>
                    </>
                  )}

                {booking.approved &&
                  booking.selectedShifts === booking.number_of_staff && (
                    <div className="label-wrapper">
                      <p className="green-label label">Besat</p>
                    </div>
                  )}
                      
                      <div className="booked-container">
                        <div className="selected-staff-container">
                            <p style={{ fontWeight: "500", display: 'flex' }}>
                              Ansøgere 
                            </p>


                            <div class="label-wrapper">
                              <p class="label ml-2" style={{backgroundColor: booking.status == bookingTypes.closed ? colors.green_50 : colors.grey_50}}>{!booking.approved || booking.status == bookingTypes.closed ? `Lukket` : booking.appliedShifts}</p>
                              </div>
                        </div>
                        <div className="selected-staff-container">
                            <p style={{ fontWeight: "500", display: 'flex', gap: '4px' }} className="flagsWrapper">
                            { booking.language ? booking.language === "Engelsk" ? <img src={englishFlag} width={15}/> : booking.language === "Dansk" ? <img src={danishFlag} width={15}/> : <><img src={danishFlag} width={15}/> <img src={englishFlag} width={15}/></> : ``}
                            {(booking.jobRole?.job_title) }
                            </p>


                            <div class="label-wrapper">
                              <p class="label ml-2" style={{backgroundColor: booking.selectedShifts == booking.number_of_staff ? colors.green_50 : bookingHasTime == false ? colors.darkerRed : colors.grey_30}}>{booking.selectedShifts ? booking.selectedShifts : 0} af {`${booking.number_of_staff}`}</p>
                              </div>
                        </div>
                        {
                          expanded && checkInStarted && booking.approved && !props.waitingApproval && (
                            <div className="selected-staff-container">
                              <p style={{ fontWeight: "500", display: 'flex' }}>
                                Meld Klar 
                              </p>


                              <div class="label-wrapper">
                                <p class="label ml-2" style={(totalCheckedInEmployees == booking.selectedShifts && booking.selectedShifts != 0) ? {backgroundColor:  colors.green_50} : bookingHasTime == false ? {backgroundColor:  colors.darkerRed} : {backgroundColor : colors.custardYellow, color: colors.grey_50}}>{totalCheckedInEmployees} af {booking.selectedShifts ? booking.selectedShifts : 0}</p>
                                </div>
                          </div>
                          )
                        }
                      </div>

                {/* {booking.approved &&
                  booking.selectedShifts !== booking.number_of_staff &&
                  booking.status !== bookingTypes.finished &&
                  booking.status !== "deprecated" && (
                    <div className="label-wrapper">
                      {(takenOverCorporation || is_admin) &&
                        booking.status === bookingTypes.open && (
                          <p
                            style={{ marginRight: "0.25rem" }}
                            className="light-grey-label"
                          >
                            ON
                          </p>
                        )}
                      <p className="yellow-label">GODKENDT - SØGER</p>
                    </div>
                  )}

                {booking.approved &&
                  booking.selectedShifts !== booking.number_of_staff &&
                  booking.status !== bookingTypes.finished &&
                  booking.status === "deprecated" && (
                    <>
                      <p className="green-label">Færdig</p>
                    </>
                  )}

                {booking.approved &&
                  booking.selectedShifts === booking.number_of_staff &&
                  booking.status !== bookingTypes.finished && (
                    <div className="label-wrapper">
                      {(takenOverCorporation || is_admin) &&
                        booking.status === bookingTypes.open && (
                          <p
                            style={{ marginRight: "0.25rem" }}
                            className="light-grey-label"
                          >
                            ON
                          </p>
                        )}
                      <p className="green-label">BESAT</p>
                    </div>
                  )}

                // ENDED BOOKINGS 
                {booking.approved &&
                  booking.status === bookingTypes.finished &&
                  booking.missing_check_out &&
                  !booking.all_check_times_approved && (
                    <>
                      <p className="red-label">STEMPEL UD</p>
                    </>
                  )}

                {(booking.approved &&
                  booking.status === bookingTypes.finished &&
                  !booking.missing_check_out &&
                  !booking.all_check_times_approved &&
                  !booking.missing_rating) ||
                  (booking.all_check_times_approved && (
                    <>
                      <p className="green-label">FÆRDIG</p>
                    </>
                  ))}

                {booking.approved &&
                  booking.status === bookingTypes.finished &&
                  !booking.missing_check_out &&
                  !booking.all_check_times_approved &&
                  booking.missing_rating && (
                    <>
                      <p className="yellow-label">ANGIV RATINGS</p>
                    </>
                  )}

                {booking.all_check_times_approved && (
                  <>
                    <p className="grey-label mt-2">FAKTURA SENDT</p>
                  </>
                )} */}

                {/* <div className="booked-container">
                  <div className="selected-staff-container">
                      <p style={{ fontWeight: "500", display: 'flex' }}>
                      { booking.language ? booking.language === "Engelsk" ? `🇬🇧 ` : booking.language === "Dansk" ? `🇩🇰 ` : `🇩🇰 🇬🇧` : ``}
                      {(booking.jobRole?.job_title)?.toUpperCase() }
                      </p>
                    <p
                      style={{ fontWeight: "500" }}
                    >{`${booking.number_of_staff}`}</p>
                  </div>

                  {booking.approved &&
                    booking.status !== bookingTypes.deprecated && (
                      <Fragment>
                        <div className="selected-staff-container">
                          <p>BOOKET</p>
                          <p>
                            {booking.selectedShifts ? booking.selectedShifts : 0}
                          </p>
                        </div>

                        {booking.status === bookingTypes.open && (
                          <div className="applied-staff-container">
                            <p>ANSØGERE</p>
                            <p>
                              {booking.appliedShifts ? booking.appliedShifts : 0}
                            </p>
                          </div>
                        )}
                      </Fragment>
                    )}
                </div> */}

              </div>
            </div>
          </div>
          {
            expanded && !props.waitingApproval && (
              <div className="expanded-content"  onClick={(e) => e.stopPropagation()}>
                <ol>
                  {

                    Array.from(Array(booking.number_of_staff), (e, i) => {
                      let id = selectedEmployees[i]?.id || ``;
                      let firstName = selectedEmployees[i]?.employee?.first_name || ``;
                      let lastName = selectedEmployees[i]?.employee?.last_name || ``;
                      let phone = selectedEmployees[i]?.employee?.phone || `N/A`;
                      let display_phone_status = selectedEmployees[i]?.display_phone_status || 0;
                      let is_checked = selectedEmployees[i]?.is_checked || false;

                      if(lastName){
                        lastName = lastName.charAt(0).toUpperCase();
                      }
                      let activeClass = selectedEmployees[i]?.employee_checkin;
                      return <DisplayNumber id={id} phoneNumber={phone} firstName={firstName} lastName={lastName} approved={booking.approved} displayPhoneStatus={display_phone_status} isCheckedStatus={is_checked} bookingHasTime={bookingHasTime} activeClass={activeClass} checkInStarted={checkInStarted} index={i+1}/>
                    })              
                  }
                  </ol>
              </div>  
            )
          }
          <div className="buttons-list" onClick={(e) => {e.stopPropagation();}}>
            {
              expanded && !props.waitingApproval && (
                <div
                onClick={copySelectedEmployees}
                className="box box1"
              >
                <p>KOPIER LISTE</p>
              </div>  
              )
            }

            {
              !props.waitingApproval && (
                <div className="show-more" onClick={showMore}>
                  {
                    !loading ? <button><DotsHorizontalIcon size={50}/></button> : <Spinner size={20} />
                  }
                </div>  
              )
            }
            {
              expanded && !props.waitingApproval && (
                <div className="box-group">
                  <div
                    onClick={() => onSubmitHandler()}
                    className="box"
                  >
                    <p>ÅBEN</p>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/editbooking/${booking.id}?review=1`)
                    }}
                    className="box"
                  >
                    <p>REDIGER</p>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (colors, loading, bookingHasTime) => css`

position: relative;
cursor: pointer;
border-radius: 0.25rem;
padding: 0rem;
margin: 1.25rem 0;
  .icon-right-gap {
    margin-right: 7px;
  }
  @media screen and (max-width: ${breakPoint.sm}px) {
    margin-top: 1rem;
  }


  p.date-detail {
    font-size: 1.7rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 1rem;
    }
  }

  .label-wrapper {
    display: flex;
    align-items: baseline;
    .label{
      text-align: center;
      padding: 0.08rem 1rem;
      width: 75px;
      min-width: 75px;
      border-radius: 0.25rem;
      align-items: center;
      color: ${colors.white};
      font-weight: 600;
      font-size: 0.75rem !important;
    }
    .on-label {
      margin-right: 0.25rem;
    }
  }

  .inside-component {
    display: flex;
    align-items: baseline;
    .date-container {
      display: flex;
      flex: 0 0 auto;
      max-width: 8%;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;

      .date-style {
        .day,
        .month {
            font-size: 20px;
            line-height: 25px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 12px;
          }
        }

        .year {
          left: 20px;
          bottom: 4px;
          position:initial;  
          font-size: 20px;
          line-height: 25px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            bottom: 6px;
            font-size: 12px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 15px;
          }
        }
      }
    }

    .content-box {
      max-width: 92%;
      width: 100%;
      padding: 0.2rem .5rem 0.5rem;
      border: 1px solid #979797;
      background-color: ${colors.white};
      border-radius:5px;
      @media screen and (max-width: ${breakPoint.sm}px) {
        padding: 0.2rem .5rem 1rem;
      }

      .default-content{
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;  
        align-items: flex-start;
      }
      .expanded-content{
        z-index:1;
        position: relative;
        font-size: 12px;
        padding: 10px 0;
        ol{
          list-style-type: auto;
          list-style-position: inside;
          padding: 0 20px;
          li{
            position: relative;
            ${!bookingHasTime ? "padding: 7px 0; font-size: 14px;" : "padding: 2px 0; font-size: 12px;"}
            
            display: flex;
            span{
              display: inline-flex;
              align-items: center;
            }
            .dot {
              height: 9px;
              width: 9px;
              background-color: #d1d105;
              border-radius: 50%;
              display: inline-block;
              margin-right: 5px;
              border: 1px solid #00038;
              position: absolute;
              left: -12px;
              top: 48%;
              transform: translate(0, -50%); 
            }
            .check {
              display: inline-block;
              margin-right: 5px;
              position: absolute;
              left: -17px;
              top: 56%;
              transform: translate(0, -50%); 
            }
            .active {
              background-color: #008000bd;
            }          
          }
        }
      }

      .buttons-list {
        position: absolute;
        bottom: ${loading? '-12px' : '-31px'};
        display: flex;
        align-items: center;
        width: 87%;
        z-index:0;

        .box-group{
          display: flex;
          gap: 10px;
          left: 65%;
          position: absolute;

        }
        .box1{
          left: 5%;
          position: absolute;
        }
        .box {
          cursor: pointer;
          border-radius: 0.25rem;
          background-color: ${colors.white};
      
          p {
            padding: 0.25rem 1rem;
            font-size: 0.75rem;
            font-weight: bold;
            border: 1px solid ${colors.darkerGrey};
            border-radius: 3px;
            color: ${colors.grey_50};
            @media screen and (max-width: ${breakPoint.sm}px) {
              padding: 0.25rem;
              font-size: 0.55rem;
            }          
          }
        }
      
        .show-more{
          color: #fff;
          min-width: 100px;
          text-align: center;
          position: relative;
          left: calc(50% - 50px);
          button{
            outline: none;
          }
        }
  
      }
      .mdi-icon {
        color: #4A4A4A;
      }
      .container-a {
          overflow: hidden;
          max-width: 60%;
        .container-a-title {
          display: -webkit-box;
          -webkit-box-align: center;
          align-items: center;
          font-size: 1.25rem;
          margin-bottom: 0.25rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          flex: 0 0 auto;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.55rem;
          }
        }

        .container-a-b {
          .time-container {
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;

            p {
              font-size: 1rem;
              margin-right: 0.5rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }
          }

          .wage-container {
            margin-bottom: 0.25rem;

            p {
              font-size: 0.75rem;
              color: ${colors.darkerGrey};
            }
          }

          .address-container, .staff-type-container {
            display: flex;
            align-items: center;

            p {
              font-size: 0.65rem;
              color: ${colors.darkerGrey};

              @media screen and (max-width: ${breakPoint.sm}px) {
                font-size: 0.55rem;
              }
            }

            svg {
              height: 1.25rem;
              width: 1.25rem;
            }

            @media screen and (max-width: ${breakPoint.sm}px) {
              svg {
                height: 0.85rem;
                width: 0.85rem;
              }
            }
          }
        }
      }

      .container-b {
        display: flex;
        align-items: center;

        @media screen and (max-width: ${breakPoint.sm}px) {
          padding-top: 0;
        }

        .container-a-b {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .booked-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.55rem;
            }

            .staffs {
              margin-bottom: 0.25rem;
              text-align: end;
            }

            .selected-staff-container {
              display: flex;
              justify-content: space-between;
              margin-top: 5px;
              align-items: center;
              .flagsWrapper{
                img{
                  width: 17px;
                  height: 20px;
                }
              }
              p {
                font-size: 0.875rem;
                align-items: center;
                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }

            .applied-staff-container {
              display: flex;
              justify-content: space-between;
              width: 7rem;

              @media screen and (max-width: ${breakPoint.sm}px) {
                width: 4rem;
              }

              p {
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                color: ${colors.darkGrey};

                @media screen and (max-width: ${breakPoint.sm}px) {
                  font-size: 0.55rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .countdown-container {
    margin-top: 0.25rem;

    p {
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.65rem;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
    }
  }

  .label-container,
  .light-grey-label,
  .grey-label,
  .green-label,
  .yellow-label,
  .red-label {
    text-align: center;
    padding: 0.25rem 1rem;
    min-width: 8rem;
    border-radius: 0.25rem;
    align-items: center;
    color: ${colors.white};
    font-weight: 600;
    font-size: 0.65rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.4rem;
      min-width: 0;
    }
  }

  .green-label {
    background-color: ${colors.green_50};
  }

  .light-grey-label {
    background-color: ${colors.grey};
    min-width: 2rem;
  }

  .red-label {
    background-color: ${colors.red};
  }

  .grey-label {
    background-color: ${colors.darkerGrey};
  }

  .yellow-label {
    background-color: ${colors.custardYellow};
    color: ${colors.grey_50};
  }
  input[id^="cb-"] + label {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid ${colors.red};
    border-radius: 2px;
    cursor: pointer;
  }
  input[id^="cb-"] + label.activecheckbox{
    border: 2px solid ${colors.green_50};
  }
  input[id^="cb-"]:checked + label:after {
    position: relative;
    top: -5px;
    left: 0;
    content: '✓';
    font-size: 13px;
  }
  input[id^="cb-"] {
    display: none;
  }
  `;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
});

export default connect(null, mapDispatchToProps)(Booking);
