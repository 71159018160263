//Libs
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// Components
import PageContainer from "../ui/PageContainer";
import NavigateBackArrow from "../ui/NavigateBackArrow";
import StaffSkillsDetails from "./StaffSkillsDetails";
import GlobalBookingMessage from "./GlobalBookingMessage";
import GlobalDefaultFee from "./GlobalDefaultFee";

//Styles
import colors from "../../styles/colors";
import AddIcon from "mdi-react/AddIcon";

//Actions
import { showModal } from "../../actions/uiActions";
import { getStaffs } from "../../actions/staffActions";

const StaffType = (props) => {
  const history = useHistory();
  const [skillId, setSkillId] = useState(1);
  const [lastSkillId, setLastSkillId] = useState();
  const [newSkillName, setNewSkillName] = useState()
  useEffect(() => {
    props.getStaffs();
  }, []);

  // Redux state

  const { staffs, loading, error, endOfFeed } = useSelector(
    (state) => state.staff
  );

  return (
    <PageContainer showNavigation={true} showFullScreen={true}>
      <NavigateBackArrow navigateURL={() => history.push("/")} />

      <div className={componentStyle(colors)}>
        <div className="skill-type-container">
          <p>Type personale</p>
          <ul>
            {staffs.map((staff, index) => {
              return (
                <li
                  className={skillId === staff.id ? "selected-skill" : ""}
                  key={index}
                  onClick={() => {
                    setSkillId(staff.id);
                    setNewSkillName('')
                  }}
                >
                  {staff.job_title}
                </li>
              )
            })}
            {newSkillName && <li>{newSkillName}</li>}
          </ul>
          <AddIcon
            className="add-new-icon"
            onClick={() => {
              setSkillId('addNewSkill')
              setLastSkillId(lastSkillId ? lastSkillId : staffs[staffs.length - 1].id)
              setNewSkillName(staffs[staffs.length - 1].job_title)
              props.getStaffs();
            }}
          />



          <p onClick={() => {
                    setSkillId("global_message");
                    setNewSkillName("Global Message")
                  }}>Beskeder</p>
          <p onClick={() => {
                    setSkillId("default_fee");
                    setNewSkillName("Default Fee")
                  }}>Default Fee</p>

        </div>

        <div className="staff-container">
          {skillId == "global_message" ? <GlobalBookingMessage /> : skillId == "default_fee" ? <GlobalDefaultFee /> : <StaffSkillsDetails skillId={skillId} lastSkillId={lastSkillId}
            setLastSkillId={(data) => {
              setLastSkillId(data.id)
              staffs.push(data)
            }
            }
            setNewSkillName={skillId == 'addNewSkill' ? setNewSkillName : ''} />
          }
        </div>
      </div>
    </PageContainer>
  );
};

const componentStyle = (colors) => css`
  background-color: ${colors.superLightGrey};
  display: flex;

  .skill-type-container {
    height: 100vh;
    width: 200px;
    text-align: center;
    margin: 0 10px 0 10px;
    border: 1px solid #808080c9;
    padding: 2rem;
    text-align: left;

    ul {
      padding-left: 1rem;
    }

    p {
      font-weight: bold;
      color: #000000e0;
    }

    li {
      list-style: circle;
      color: #000000e0;
      cursor: pointer;
      word-wrap: break-word;
      font-size: 0.9rem;
      &:hover {
        font-weight: bold;
      }
    }

    .add-new-icon {
      margin-left: 1rem;
      width: 14px;
      height: 14px;
    }

    .selected-skill{
      font-weight: bold;
      color: #000000e0;
    }
  }

  .add-new-skill {
    width: 98%;
    margin: 5px;
    border-radius: 5px;
    background: #80808042;
    padding: 5px;
    color: black;
  }

  .staff-container {
    margin-left: auto;
    width: calc(100% - 220px);
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  getStaffs: bindActionCreators(getStaffs, dispatch),
});

export default connect(null, mapDispatchToProps)(StaffType);
