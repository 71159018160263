// Modules
import React, { Fragment, useEffect, useState } from "react";
import PhoneIcon from "mdi-react/PhoneIcon";
import { css } from "emotion";
import Spinner from "../ui/Spinner";
import req from "../../utilities/request-utility";


const DisplayNumber = (props) => {
  const { index, approved, firstName, lastName, displayPhoneStatus, id, bookingHasTime, checkInStarted, activeClass, isCheckedStatus} = props;
  const [displayNumber, setDisplayNumber] = useState(0);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setDisplayNumber(displayPhoneStatus)
  }, [displayPhoneStatus])
  useEffect(() => {
    setIsChecked(isCheckedStatus)
  }, [isCheckedStatus])

  const handleDisplayPhone = async (displayNumber) => {
    setPhoneLoading(id)
    req()
      .put(`corporations/shifts/${id}/change-shift-ui-status`, { display_phone_status: displayNumber })
      .then(() => {
        setDisplayNumber(displayNumber)
        setPhoneLoading(false)
      }).catch(() => {
        setPhoneLoading(false);
      });
  }
  const handleCheckBox = async () => {
    setIsChecked((prevCheckValue) => !prevCheckValue)
    req()
      .put(`corporations/shifts/${id}/change-shift-ui-status`, { is_checked: !isChecked })
      .then(() => {
        setPhoneLoading(false)
      }).catch(() => {
        setPhoneLoading(false);
      });
  }
  return (
    <li key={id} >
      <span style={{width: '20px', textAlign: 'center'}}>{index}.</span>
      {bookingHasTime == false && approved && firstName ?
        <div className={`check`}>
          <input type="checkbox" id={`cb-${id}`} checked={isChecked} onChange={() => handleCheckBox()}/>
          <label for={`cb-${id}`} className={`${activeClass ? 'activecheckbox' : ''}`}></label>
        </div>
        :
        checkInStarted && approved && firstName ? (
          <span
            className={`dot ${activeClass ? 'active' : ''}`}
          ></span>
        ) :
          <></>}
      <span>
        <span style={{whiteSpace: 'nowrap'}}>{`${firstName} ${lastName}`}</span>
        {
          bookingHasTime == false && approved && firstName && (
            <span onClick={() => !phoneLoading && displayNumber < 2 && handleDisplayPhone(displayNumber + 1)}>.......................................<PhoneIcon size={15} /> {phoneLoading == id ? <Spinner size={20} /> : displayNumber == 0 ? `Vis tlf. nr.` : displayNumber == 1 ? `Vi ${firstName} nr. klik igen!` : <a href={`tel:${props.phoneNumber}`} >{props.phoneNumber}</a>} </span>
          )
        }
      </span>

    </li>
  );
}
const componentStyle = (colors, loading, checkInStarted) => css`
  `;



export default DisplayNumber;
