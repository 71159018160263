//Libs
import { css } from "emotion";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

//Styles
import colors from "../../styles/colors";

//Components
import TextArea from "../ui/TextArea";
import Button_DEPRECATED from "../ui/Button_DEPRECATED";

// Utilities and config
import req from "../../utilities/request-utility";

//Actions
import { addToast } from "../../actions/uiActions";

const GlobalBookingMessage = (props) => {
  const [globalMessage, setGlobalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    req()
    .get(`admin/bookings/global-message`)
    .then((res) => {
      const message = res.data.global_message || "";
      setGlobalMessage(message)
    });
  }, [])
  const updateMessage = async() => {
    setLoading(true)
    await req()
    .put(`admin/bookings/update-global-message`, {
      message: globalMessage
    })
    .then(() => {
      setLoading(false)

      props.addToast({
        message: "opdatering af den globale besked lykkedes",
        type: "success",
        autoDismiss: true,
      });
    })
    .catch(() => {
      setLoading(false)

      props.addToast({
        message: "opdatering mislykkes",
        type: "error",
        autoDismiss: true,
      });
    });

  }
  return (
    <div className={componentStyle(colors)}>
      <div className="bottom-container">
      <p>Global meld klar besked:</p>
      <TextArea
                rows={20}
                name="admin_global_message"
                className="description-text"
                placeholder="Global meld klar besked"
                value={globalMessage}
                onChange={(e) => {
                  setGlobalMessage(e.target.value);
                }}
              />

        <Button_DEPRECATED
          className="update-message"
          onClick={() => {
            updateMessage();
          }}
          disabled={loading}
          title={loading ? `opdatering...`: `opdatere global besked`}
        />
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
  .bottom-container {
    max-width: 500px;
    margin-right: auto;


      TextArea {
        width: 100%;
        color: black;
        margin-top: 3px;
        font-size: 13px;
        border-radius: 3px;
        padding: 5px 0 5px 10px;
        border: 1px solid #808080ad;
      }



    .update-message {
      margin-top: 20px;
      padding: 0.55rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(GlobalBookingMessage);
