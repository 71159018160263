//Libs
import { css } from "emotion";
import Switch from "react-switch";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

//Styles
import colors from "../../styles/colors";
import HelpCircleIcon from "mdi-react/HelpCircleIcon";
import UploadIcon from "mdi-react/UploadIcon";

//Components
import TextArea from "../ui/TextArea";
import Button_DEPRECATED from "../ui/Button_DEPRECATED";
import CircularRating from "../ui/CircularRating";

// Utilities and config
import req from "../../utilities/request-utility";

//Actions
import { changeSkillStatus, changeSkillName } from "../../actions/staffActions";
import { addToast } from "../../actions/uiActions";
import ImageUpload from "../ui/ImageUpload";

const StaffSkillsDetails = (props) => {
  //current skill id
  const skillId = props.skillId;
  const renderNewSkill = 5;
  
  const formattedSkill = {
    skill_title: "",
    skill_description: "",
    question_icon_url: "",
    question_title: "",
    question: "",
    answer: [
      { text: "", value: "" },
      { text: "", value: "" },
      { text: "", value: "" },
      { text: "", value: "" },
      { text: "", value: "" },
    ],
  };

  let [skillDetails, setSkillDetails] = useState([
    { id: "", job_title: "", skill_detail: {} },
    { id: "", job_title: "", skill_detail: {} },
    { id: "", job_title: "", skill_detail: {} },
    { id: "", job_title: "", skill_detail: {} },
    { id: "", job_title: "", skill_detail: {} },
  ]);

  const [newSkill, setNewSkill] = useState(
    Array(renderNewSkill).fill(formattedSkill)
  );
  const [profileSpinner, setProfileSpinner] = useState();
  const [jobTitle, setJobTitle] = useState({
    skill_name: "",
    skill_worker: false,
    skill_company: false,
  });

  // Redux state
  const { staffs } = useSelector((state) => state.staff);

  //show details of either first skill or selected skill
  let staff = skillId
    ? staffs.find(
        (s) => s.id == (skillId == "addNewSkill" ? props.lastSkillId : skillId)
      )
    : staffs[0];
  let { id, job_title, worker, company, billy_id } = staff || {};

  useEffect(() => {
    props.skillId == "addNewSkill" ? (worker = false) : (worker = worker);
    props.skillId == "addNewSkill" ? (company = false) : (company = company);
    
    props.skillId !== "addNewSkill"
      ? setJobTitle({ ...jobTitle, skill_name: job_title })
      : setJobTitle({ ...jobTitle, skill_name: `${job_title}` });
      
    const skillId =
      props.skillId == "addNewSkill"
        ? props.lastSkillId
        : props.skillId
        ? props.skillId
        : 1;
    req()
      .get(`admin/staffs/${skillId}/get-skill-detail`)
      .then(({ data: skillQuestions }) => {
        setSkillDetails(
          skillQuestions.map((skillQuestion, i) => {
            return {
              id: skillQuestion.id,
              job_title: skillQuestion.job_title,
              skill_detail: skillQuestion.skill_detail ?? formattedSkill,
            };
          })
        );
      })
      .catch((err) => {
        props.addToast({
          message: "Fejl",
          type: "error",
          autoDismiss: true,
        });
      });
  }, [id]);

  const updateSkillDetail = (id, e, index) => {
    if (index === undefined) {
      setSkillDetails(
        skillDetails.map((sd, i) => {
          return sd.id !== id
            ? sd
            : {
                id: sd.id,
                job_title: sd.job_title,
                skill_detail: {
                  ...sd.skill_detail,
                  [e.target.name]: e.target.value,
                },
              };
        })
      );
    } else {
      setSkillDetails(
        skillDetails.map((sd, i) => {
          return sd.id !== id
            ? sd
            : {
                id: sd.id,
                job_title: sd.job_title,
                skill_detail: {
                  ...sd.skill_detail,
                  answer: sd.skill_detail.answer.map((sa, i) => {
                    return i !== index
                      ? sa
                      : { value: i + 1, text: e.target.value };
                  }),
                },
              };
        })
      );
    }

    if (e.target.name == "job_title") {
      setJobTitle({ ...jobTitle, skill_name: e.target.value });
      if (props.skillId == "addNewSkill") {
        props.setNewSkillName(e.target.value);
      }
    }
  };

  const setWorkerSkills = async () => {
    if (id == undefined) {
      setJobTitle({ ...jobTitle, skill_worker: !jobTitle.skill_worker });
    } else {
      await req().put(`admin/staffs/set-staffs-skills/${id}`, {
        WorkerCompany: "worker",
        worker,
      });
    }
    props.changeSkillStatus(id, "worker", worker);
  };

  const setCompanySkills = async () => {
    if (id == undefined) {
      setJobTitle({ ...jobTitle, skill_company: !jobTitle.skill_company });
    } else {
      await req().put(`admin/staffs/set-staffs-skills/${id}`, {
        WorkerCompany: "company",
        company,
      });
    }
    props.changeSkillStatus(id, "company", company);
  };

  const updateSkillQuestions = async () => {
    //remove the current staff from staffs array
    const filterStaffs = staffs.filter(staff => staff.id != skillId)

    //now find if skill already exist or not
    const sameSkillName = filterStaffs.find(staff => staff.job_title == jobTitle.skill_name )
    
    if (sameSkillName) {
      props.addToast({
        message: "Personaletype findes allerede",
        type: "error",
        autoDismiss: true,
      });
    }else {
      await req()
        .put(`admin/staffs/${id}/update-skill-question`, {
          skillDetails,
          jobTitle,
          billy_id
        })
        .then(() => {
          props.addToast({
            message: "Opdateringen lykkedes",
            type: "success",
            autoDismiss: true,
          });
        })
        .catch(() => {
          props.addToast({
            message: "opdatering mislykkes",
            type: "error",
            autoDismiss: true,
          });
        });
      props.changeSkillName(id, jobTitle.skill_name);
    }
  };

  const uploadProfileImage = (file, skillId) => {
    let latestSkill;
    skillDetails.map((skills, i) => {
      if (skills.id === skillId) {
        latestSkill = {
          ...skills.skill_detail,
          question_icon_url: file?.imageData?.secureUrl,
        };
        let imgTag = document.getElementById(`img-${skillId}`);
        imgTag.setAttribute("src", file?.imageData?.secureUrl);
        imgTag.style.display = "block";
        skillDetails[i].skill_detail = latestSkill;
      } else {
        skillDetails[i].skill_detail = skills.skill_detail;
      }
    });
  };

  const createNewSkillQuestions = async () => {
    skillDetails.map((skills, i) => {
      newSkill[i] = skills.skill_detail;
    });
    await req()
      .post(`admin/staffs/add-new-skill`, {
        newSkill,
        jobTitle,
      })
      .then((data) => {
        props.setLastSkillId(data.data);
        setJobTitle({ ...jobTitle, skill_name: data.data });
        props.addToast({
          message: "Ny færdighed tilføjet",
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        props.addToast({
          message: "Tilføjelse af nye færdigheder mislykkes",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className={componentStyle(colors)}>
      <div className="top-container">
        <div className="worker-option">
          <p>Worker {worker || jobTitle.skill_worker ? "(hide)" : "(Show)"}</p>
          <Switch
            className="worker-switch"
            onChange={() => {
              setWorkerSkills();
            }}
            checked={worker || jobTitle.skill_worker}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
        <div className="company-option">
          <p>
            Company {company || jobTitle.skill_worker ? "(hide)" : "(Show)"}
          </p>
          <Switch
            className="company-switch"
            onChange={() => {
              setCompanySkills();
            }}
            checked={company || jobTitle.skill_company}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
      </div>

      <div className="bottom-container">
        <div className="staff-type">
          <input
            type="text"
            name="job_title"
            value={jobTitle?.skill_name || ''}
            placeholder="færdighedsnavn"
            onChange={(e) => {
              updateSkillDetail(skillDetails.job_title_id, e);
            }}
          />
        </div>

        {skillDetails.map((skillDetail, index) => (
          <div key={index}>
            <div className="header-component">
              <input
                type="text"
                name="skill_title"
                className="sub-title"
                value={skillDetail?.skill_detail?.skill_title || ''}
                placeholder="færdighedstitel"
                onChange={(e) => {
                  updateSkillDetail(skillDetail.id, e);
                }}
              />
              <TextArea
                rows={4}
                name="skill_description"
                className="description-text"
                placeholder="færdighedsbeskrivelse"
                value={skillDetail?.skill_detail?.skill_description}
                onChange={(e) => {
                  updateSkillDetail(skillDetail.id, e);
                }}
              />
            </div>

            <div className="skill-icon">
              <ImageUpload
                spinner={setProfileSpinner}
                index={index}
                // url={`admin/staffs/${staffs}`}
                onFile={(file) => {
                  uploadProfileImage(file, skillDetail.id);
                }}
              >
                <div>
                  <UploadIcon size={20} className="icon" />
                </div>
              </ImageUpload>
              <img
                id={`img-${skillDetail.id}`}
                src={
                  skillDetail?.skill_detail?.question_icon_url
                    ? skillDetail?.skill_detail?.question_icon_url
                    : null
                }
                style={{
                  marginLeft: "5px",
                  height: "20px",
                  width: "20px",
                  display: skillDetail?.skill_detail?.question_icon_url
                    ? "block"
                    : "none",
                }}
              />
            </div>

            <div className="question-answer-container">
              <input
                type="text"
                name="question_title"
                placeholder="spørgsmålets titel"
                value={skillDetail?.skill_detail?.question_title || ''}
                onChange={(e) => {
                  updateSkillDetail(skillDetail.id, e);
                }}
              />
              <div className="field-wrapper">
                <input
                  type="text"
                  name="question"
                  placeholder="spørgsmål"
                  value={skillDetail?.skill_detail?.question || ''}
                  onChange={(e) => {
                    updateSkillDetail(skillDetail.id, e);
                  }}
                />
                <HelpCircleIcon size={14} />
              </div>

              {skillDetail.skill_detail.answer?.map((singleAnswer, index) => (
                <div className="field-wrapper" key={index}>
                  <input
                    type="text"
                    name="text"
                    value={singleAnswer?.text}
                    placeholder={`Mulighed ${index + 1}`}
                    onChange={(e) => {
                      updateSkillDetail(skillDetail.id, e, index);
                    }}
                  />
                  <CircularRating value={index + 1} page="staffSkillPage" />
                </div>
              ))}
            </div>
          </div>
        ))}

        <Button_DEPRECATED
          className="update-skill"
          onClick={() => {
            skillId == "addNewSkill"
              ? createNewSkillQuestions()
              : updateSkillQuestions();
          }}
          title={
            skillId == "addNewSkill" ? "Tilføj ny færdighed" : "Gem ændringer"
          }
        />
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
  .top-container {
    display: flex;
    margin-top: 10px;
    max-width: 500px;
    font-weight: bold;
    margin-right: auto;
    justify-content: space-evenly;

    .worker-switch {
      margin-left: 30px;
    }

    .company-switch {
      margin-left: 35px;
    }
  }

  .bottom-container {
    max-width: 500px;
    margin-right: auto;

    .staff-type {
      width: 185px;
      margin-top: 25px;
      margin-left: 82px;

      input {
        width: 100%;
        font-weight: 600;
        color: #000000e0;
        border-radius: 3px;
        padding-left: 10px;
        border: 1px solid #808080ad;
      }
    }

    .header-component {
      width: 340px;
      display: flex;
      margin-top: 60px;
      margin-left: 83px;
      border-radius: 4px;
      flex-direction: column;

      input {
        color: black;
        width: 54.5%;
        font-size: 14px;
        font-weight: 500;
        border-radius: 3px;
        padding: 2px 0px 2px 10px;
        border: 1px solid #808080ad;
      }

      TextArea {
        width: 100%;
        color: black;
        margin-top: 3px;
        font-size: 13px;
        border-radius: 3px;
        padding: 5px 0 5px 10px;
        border: 1px solid #808080ad;
      }
    }

    .skill-icon {
      display: flex;
      align-icon: center;
      margin: 10px 0 0 83px;

      .icon {
        color: gray;
        margin-left: 10px;
      }
    }

    .question-answer-container {
      display: flex;
      margin: 0 0 0 83px;
      flex-direction: column;

      .field-wrapper {
        display: flew;
        align-items: center;

        svg {
          color: #718096;
          margin: 10px 0 0 10px;
        }
      }

      input {
        width: 340px;
        color: black;
        font-size: 14px;
        margin-top: 10px;
        border-radius: 4px;
        padding: 2px 0px 2px 10px;
        border: 1px solid #808080ba;
      }
    }

    .update-skill {
      margin-top: 20px;
      padding: 0.55rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  changeSkillStatus: bindActionCreators(changeSkillStatus, dispatch),
  changeSkillName: bindActionCreators(changeSkillName, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(StaffSkillsDetails);
