import {
  END_OF_EMPLOYEES,
  GET_EMPLOYEES,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS,
  GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS,
  RESET_EMPLOYEES,
  DELETE_EMPLOYEE
} from "../actions/actionTypes";

const initialState = {
  employees: [],
  totalEmployees: 0,
  loading: false,
  endOfFeed: false,
  error: false,
  searchTerm: "",
  staffType: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        loading: true,
        error: false,
        endOfFeed: false
      };

    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        employees: [...state.employees, ...action.payload.employees],
        totalEmployees: action.payload.totalEmployees,
        endOfFeed: false
      };

    case GET_EMPLOYEES_WITH_SAME_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        employees: [...state.employees, ...action.payload.employees],
        totalEmployees: action.payload.totalEmployees,
        searchTerm: action.payload.searchTerm,
        staffType: action.payload.staffType,
        endOfFeed: false
      };
    case GET_EMPLOYEES_WITH_NEW_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        employees: [...action.payload.employees],
        totalEmployees: action.payload.totalEmployees,
        searchTerm: action.payload.searchTerm,
        staffType: action.payload.staffType,
        endOfFeed: false
      };

    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case END_OF_EMPLOYEES:
      return {
        ...state,
        loading: false,
        error: false,
        endOfFeed: true,
        searchTerm: action.payload.searchTerm,
        staffType: action.payload.staffType,
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        employees: state.employees.filter((emplyee) => emplyee.id != action.payload.id)
      };

    case RESET_EMPLOYEES:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
