//libs
import moment from "moment";

export const isBookingOpen = (bookingDate,startTime) => {
  // Get the current date and time using Moment.js
  let currentDate = moment();
  let currentTime = moment().format('HH:mm');

  // Combine the booking date and start time into a single Moment.js object
  let bookingDateTime = moment(bookingDate + ' ' + startTime, 'YYYY-MM-DD HH:mm');

  // Calculate the booking close time by subtracting 2 hours from the booking date and start time
  let bookingCloseTime = bookingDateTime.add(2, 'hours');

  // Compare the current date and time with the booking close time
  if (currentDate.isAfter(bookingCloseTime) || (currentDate.isSame(bookingCloseTime, 'day') && currentTime >= startTime)) {
    // Booking is closed
    return false;
  } else {
    // Booking is open
    return true;
  }
}
export const isBeforeBookingTime = (bookingDate,startTime, hoursToCheck = 2) => {
  // Get the current date and time using Moment.js
  let currentDate = moment();

  // Combine the booking date and start time into a single Moment.js object
  let bookingDateTime = moment(bookingDate + ' ' + startTime, 'YYYY-MM-DD HH:mm');

  // Calculate the booking close time by subtracting 2 hours from the booking date and start time
  let hoursBefore = bookingDateTime.clone().subtract(2, "hours");

  // Compare the current date and time with the booking close time
  if (currentDate.isAfter(hoursBefore)) {
    // Booking is closed
    return false;
  } else {
    // Booking is open
    return true;
  }
}

