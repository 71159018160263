import React, { useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { addToast } from "../../actions/uiActions";
import { connect } from "react-redux";
import req from "../../utilities/request-utility";
import axios from "axios";


const ImageUpload = props => {
  const fileInput = useRef(null);
  const [image, setImage] = useState({});

  const onFileSelect = e => {
    // set the spinner
    props.spinner(true);

    // TO DO Validate file size and type
    let file = e.target.files[0];

    // Check if file is selected
    if (!file) return; // No file is selected

    if (file.size > 200000000) {
      return props.addToast({
        message: "Filen er for stor",
        type: "warning",
        autoDismiss: true
      });
    }

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET)
    // Start upload and set state
    uploadFile(formData);
    // Reset input
    e.target.value = "";
  };

  const uploadFile = file => {
    axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, file)
      .then(data => {
        let imageData = {
          secureUrl: `${data?.data?.secure_url}`,
          imageName: `${data?.data?.public_id}.${data?.data?.format}`
        }
        onFileUploadSuccess(imageData)
      }
        )
      .catch(err => onFileUploadFailure(err));
  };

  const onUploadProgress = progress => {
    let progressPercent = (progress.loaded / progress.total) * 100;
    setImage({ ...image, progress: progressPercent });
  };

  const onFileUploadSuccess = imageData => {
    props.onFile(
      {
        imageData,
      },
      setImage
    );
  };

  const onFileUploadFailure = () => {
    return props.addToast({
      message: "Kunne ikke uploade filen",
      type: "warning",
      autoDismiss: true
    });
  };

  return (
    <div onClick={() => fileInput.current.click()}>
      {image.status !== "uploading" && props.children}

      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={onFileSelect}
        className="hidden"
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ImageUpload);
