// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import ReactStars from "react-stars";
import moment from "moment";
import "moment/locale/da";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import tinyColor from "tinycolor2";

// Components
import PageContainer from "../ui/PageContainer";
import Button_DEPRECATED from "../ui/Button_DEPRECATED";
import Spinner from "../ui/Spinner";
import ArrowLeftCircleIcon from "mdi-react/ArrowLeftCircleIcon";
import ArrowRightCircleIcon from "mdi-react/ArrowRightCircleIcon";

// Styles
import colors from "../../styles/colors";
import CheckIcon from "mdi-react/CheckIcon";

// Utilities and config
import bookingTypes from "../../config/bookingTypes";
import req from "../../utilities/request-utility";

// Actions
import { takeOverCorporation } from "../../actions/authActions";
import { addToast } from "../../actions/uiActions";
import NavigateBackArrow from "../ui/NavigateBackArrow";
import {
  getAdminBookings,
  resetBookings,
  changeAllGravyStatus,
  changeWeekNumber,
  changeStatus,
  changeSendBlCount
} from "../../actions/bookingActions";
import InfiniteScroll from "react-infinite-scroller";
import ErrorBox from "../ui/ErrorBox";
import { useAsyncDebounce } from "../../utilities/useAsyncDebounce";
import { changeFilterTabUpcomingBookings } from "../../actions/uiActions";
const UpcomingBookings = (props) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [localWeek, setLocalWeek] = useState(moment().week());
  const [statusLoading, setLoading] = useState(true);

  useEffect(() => {
    // props.resetBookings();
    // const obj = { type: "admin_upcoming_bookings" };
    // if(tab === 1){
    //   obj.startDate = startDate;
    //   obj.endDate = endDate;
    // }
    // props.getAdminBookings(obj);
    // eslint-disable-next-line

    return () => {
      props.resetBookings();
    };
  }, []);

  const { bookings, loading, endOfFeed, error, searchTerm: reduxSearchTerm } = useSelector(
    (state) => state.booking.admin_upcoming_bookings
  );
  const { filterTabUpcomingBookings: tab } = useSelector(
    (state) => state.ui
  );
  const { weekNumber, weekNumberToDisplay } = useSelector(
    (state) => state.booking.upcoming
  );
  const startDateToDisplay = moment(moment().week(weekNumber).startOf("week")).format("DD");
  const endDateToDisplay = moment(moment().week(weekNumber).endOf("week")).format("DD");
  const endDate = moment(moment().week(weekNumber).endOf("week")).format("YYYY-MM-DD");
  let startDate = moment(moment().week(weekNumber).startOf("week")).format("YYYY-MM-DD");
  let today = moment();
  if (moment(today).isAfter(startDate, 'day')) {
    startDate = today.format("YYYY-MM-DD"); // Update startDate to today's date
  }

  useEffect(() => {
    if (searchTerm !== reduxSearchTerm) {
      const week = weekNumber;
      props.resetBookings();
      props.changeWeekNumber({
        weekNumber: week,
        weekNumberToDisplay: week,
        bookingType: 'upcoming'
      });
      setLocalWeek(week)
    }
  }, [searchTerm]);

  useEffect(() => {
    if (startDate && endDate && localWeek != weekNumber) {
      const week = weekNumber;
      props.resetBookings();
      props.changeWeekNumber({
        weekNumber: week,
        weekNumberToDisplay: week,
        bookingType: 'upcoming'
      });
      setLocalWeek(week)
    }
  }, [startDate, endDate, weekNumber, localWeek]);

  useEffect(() => {
    props.resetBookings();
    setLocalWeek(moment().week())
  }, [tab]);

  const debouncedGetUpcomingBookings = useAsyncDebounce(props.getAdminBookings, 250);

  const determineBackgroundColor = (
    bookingStatus,
    shiftsCount,
    numberOfStaff,
    urgentPeriod
  ) => {
    if (
      bookingStatus === bookingTypes.finished ||
      bookingStatus === bookingTypes.cancelled
    ) {
      return "finished";
    } else if (bookingStatus === bookingTypes.closed) {
      return "full-closed";
    } else if (numberOfStaff === shiftsCount) {
      return "full";
    } else if (urgentPeriod === 24 && bookingStatus !== bookingTypes.finished) {
      return "urgent-24-hour";
    } else if (urgentPeriod === 48 && bookingStatus !== bookingTypes.finished) {
      return "urgent-48-hour";
    }
  };
  const sendToAllGravy = async (id) => {
    try {
      await req().put(`admin/bookings/${id}/change-all-gravy-status`);
      props.changeAllGravyStatus({ id });
      props.addToast({
        message: "Planlagt til at sende til All Gravy..",
        type: "success",
        autoDismiss: true,
      });
    } catch (error) {
      props.addToast({
        message: "Noget gik galt. Kunne ikke gemme ændringerne..",
        type: "error",
        autoDismiss: true,
      });
    }
  };

  // const calculateRemainingTime = (date, comprisionTime) => {
  //   //todays date
  //   let currentDate = moment(new Date());

  //   let remainingTime = moment.duration(currentDate.diff(date)).asHours();

  //   remainingTime = parseInt(remainingTime * (remainingTime < 0 ? -1 : 0));

  //   return remainingTime <= comprisionTime;
  // };
  const sendBookingList = ({booking_id, send_bl_count}) => {
    setLoading(booking_id);
    req()
      .post(`corporations/bookings/${booking_id}/send-booking-list`)
      .then(() => {
        setLoading(false);
        props.changeSendBlCount({id: booking_id, send_bl_count});
        props.addToast({
          message: "Bookingliste sendt!",
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        setLoading(false);
        props.addToast({
          message: "Kunne ikke ændre bookingens status..",
          type: "error",
          autoDismiss: true,
        });
      });
  }

  const changeBookingStatus = ({ booking_id, status }) => {
    setLoading(booking_id);
    req()
      .put(`corporations/bookings/${booking_id}/change-status`, { status })
      .then(() => {
        setLoading(false);
        props.changeStatus({id: booking_id, status})
        props.addToast({
          message:
            status === "open"
              ? "Bookingen er åben!"
              : "Bookingen er lukket!",
          type: "success",
          autoDismiss: true,
        });
      })
      .catch(() => {
        setLoading(false);
        props.addToast({
          message: "Kunne ikke ændre bookingens status..",
          type: "error",
          autoDismiss: true,
        });
      });
  };
  const calculateRemainingTime = (date) => {
    const currentDate = moment();
    const momentDate = moment(date); // create a new moment object from the date variable
    const diffInDays = momentDate.diff(currentDate, 'days');

    if (diffInDays < 0) {
      // If the given date is in the past
      return true;
    } else if (diffInDays <= 2) {
      // If less than or equal to 2 days remaining until the given date
      const twoDaysBefore = momentDate.clone().subtract(2, 'days').startOf('day').add(6, 'hours');
      if (currentDate >= twoDaysBefore) {
        // If the current time is after 6:00 am on the day that is 2 days before the given date
        return true;
      }
    }

    return false;
  };
  let bookingFilters = {
    type: "admin_upcoming_bookings",
    searchTerm,
  }
  if (tab === 1) {
    bookingFilters = { ...bookingFilters, startDate, endDate, weekNumber };
  }
  if (tab === 2) {
    bookingFilters = { ...bookingFilters, status: 'open' };
  }
  return (
    <PageContainer
      backgroundColor={"white"}
      showNavigation={true}
      showFullScreen={true}
    >
      <NavigateBackArrow navigateURL={() => history.push("/")} />
      <div className={componentStyle(colors, tinyColor, tab)}>
        {/* {endOfFeed && bookings.length === 0 && (
          <div className="no-users-container">
            <p>Der er ikke nogen brugere i systemet</p>
          </div>
        )} */}
        <div className="header-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>Admin</p>
            <p>Kommende Bookinger</p>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div className="tab-navigator">
              <div className="wrapper">
                <button
                  onClick={() => props.changeTab({bookingType: 'upcoming', tab: 2})}
                  className={`${tab === 2 ? "active" : ""}`}
                >
                  VIS ÅBNE
                </button>
                <button
                  onClick={() => props.changeTab({bookingType: 'upcoming', tab: 0})}
                  className={`${tab === 0 ? "active" : ""}`}
                >
                  LISTE
                </button>
                <button
                  onClick={() => props.changeTab({bookingType: 'upcoming', tab: 1})}
                  className={`${tab === 1 ? "active" : ""}`}
                >
                  VIS UGE
                </button>
              </div>
            </div>

            <div className="week-wrapper">
              <ArrowLeftCircleIcon
                className={weekNumber === moment().week() ? 'disabled' : ''}
                onClick={() => {
                  if (weekNumber === moment().week()) return;
                  let visibleWeekNumber = weekNumberToDisplay == 1 ? 52 : weekNumberToDisplay - 1;
                  props.changeWeekNumber({
                    weekNumber: weekNumber - 1,
                    weekNumberToDisplay: visibleWeekNumber,
                    bookingType: 'upcoming'
                  });
                }}
              />
              <p>{`UGE ${weekNumberToDisplay} (${startDateToDisplay}-${endDateToDisplay})`}</p>
              <ArrowRightCircleIcon
                onClick={() => {
                  let visibleWeekNumber = weekNumberToDisplay == 52 ? 1
                    : weekNumberToDisplay + 1;
                  props.changeWeekNumber({
                    weekNumber: weekNumber + 1,
                    weekNumberToDisplay: visibleWeekNumber,
                    bookingType: 'upcoming'
                  });
                }}
              />
            </div>

            <div style={{ minWidth: "6rem", marginRight: "0.5rem", display: 'flex' }}>
              <label>Søg</label>
              <div>
                <input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <InfiniteScroll
            loadMore={() =>
              debouncedGetUpcomingBookings(bookingFilters)
            }
            useWindow={true}
            hasMore={true}
          >
            {endOfFeed && bookings.length === 0 && (
              <div className="no-users-container">
                <p>Der er ikke nogen brugere i systemet</p>
              </div>
            )}
            {bookings.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Virksomhed</th>
                    <th>Søges</th>
                    <th>Ansat</th>
                    <th>Ansøgt</th>
                    <th>Hvornår</th>
                    <th />
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                {bookings.map(
                  (
                    {
                      id,
                      corporation,
                      arrangement_type,
                      status,
                      selectedStaff,
                      hourly_wage,
                      number_of_staff,
                      totalNrOfShifts,
                      date,
                      start_time,
                      end_time,
                      created_at,
                      checkoutCompleted,
                      paid_at,
                      city,
                      approved,
                      all_check_times_approved,
                      urgentPeriod,
                      appliedStaff,
                      shifts,
                      all_gravy_status,
                      staff_type,
                      send_bl_count
                    },
                    index
                  ) => {
                    let checkInStarted = calculateRemainingTime(date)
                    return (
                      <tbody key={`old-booking-key-${index}`}>
                        <tr
                          className={determineBackgroundColor(
                            status,
                            number_of_staff,
                            selectedStaff.length,
                            urgentPeriod
                          )}
                        >
                          <td>
                            <div className="corporation-name-container">
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: colors.darkestGrey,
                                }}
                              >{`${corporation.name},`}</p>
                              <div className="event-container">
                                <p
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    color: colors.darkGrey,
                                  }}
                                >
                                  {city}
                                </p>
                                {status === "cancelled" && (
                                  <label
                                    className="cancelled-label"
                                    style={{
                                      backgroundColor: tinyColor(colors.darkGrey)
                                        .lighten(20)
                                        .toString(),
                                    }}
                                  >
                                    Annulleret
                                  </label>
                                )}
                                {status === bookingTypes.expired && (
                                  <label className="expired-label">Udløbet</label>
                                )}
                                {status === bookingTypes.finished && (
                                  <label className="finished-label">
                                    Afsluttet
                                  </label>
                                )}
                                {status === bookingTypes.open &&
                                  approved &&
                                  number_of_staff === selectedStaff.length && (
                                    <label className="open-label">Besat</label>
                                  )}
                                {status === bookingTypes.open &&
                                  approved &&
                                  number_of_staff !== selectedStaff.length && (
                                    <label className="closed-label">Søger</label>
                                  )}

                                {status === bookingTypes.closed && approved && (
                                  <>
                                    <label className="open-label">
                                      Besat og lukket
                                    </label>
                                  </>
                                )}

                                {!approved && (
                                  <label className="expired-label">
                                    Mangler godkendelse
                                  </label>
                                )}
                                {urgentPeriod && (
                                  <label className="cancelled-label">
                                    {urgentPeriod}
                                  </label>
                                )}
                                {status === bookingTypes.closed && (
                                  <Button_DEPRECATED
                                    onClick={() =>
                                      changeBookingStatus({ booking_id: id, status: "open" })
                                    }
                                    className={"take-over-btn"}
                                    title={"ÅBEN"}
                                  />
                                )}
                                {status === bookingTypes.open && (
                                  <Button_DEPRECATED
                                    onClick={() =>
                                      changeBookingStatus({ booking_id: id, status: "closed" })
                                    }
                                    className={`take-over-btn ${(number_of_staff === selectedStaff.length) ? "yellowbtn" : ""}`}
                                    title={"LUK"}
                                  />
                                )}


                                {/* <Button_DEPRECATED
                                  onClick={() => sendToAllGravy(id)}
                                  disabled={all_gravy_status != "PENDING"}
                                  className={"all-gravy-btn"}
                                  title={
                                    all_gravy_status == "PENDING"
                                      ? "Send til All Gravy"
                                      : all_gravy_status == "SCHEDULED"
                                        ? "Planlagt for All Gravy"
                                        : "sendt til All Gravy"
                                  }
                                /> */}
                              </div>
                              <Button_DEPRECATED
                                    onClick={() =>
                                      sendBookingList({booking_id: id, send_bl_count: send_bl_count + 1})
                                    }
                                    className={`take-over-btn ${send_bl_count == 0 ? "" : send_bl_count == 1 ? "greenbtn" : send_bl_count == 2 ? "yellowbtn" : "redbtn"}`}
                                    title={"SEND BL"}
                                  />
                            </div>
                            <table className="table-first-column">
                              <thead>
                                <tr>
                                  <td>
                                    <p
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        color: colors.darkGrey,
                                      }}
                                    >
                                      {`${arrangement_type}`}
                                    </p>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>{staff_type}</td>
                                </tr>
                                <tr
                                  style={
                                    status === bookingTypes.cancelled
                                      ? { backgroundColor: colors.tonedGrey }
                                      : status === bookingTypes.expired
                                        ? { backgroundColor: colors.lightGreen }
                                        : status === bookingTypes.finished &&
                                          checkoutCompleted
                                          ? {
                                            backgroundColor: colors.lightYellow,
                                          }
                                          : null
                                  }
                                >
                                  {/*<th>ID</th>*/}
                                  {/*<th*/}
                                  {/*  style={{*/}
                                  {/*    paddingLeft: "1rem",*/}
                                  {/*    width: "18rem"*/}
                                  {/*  }}*/}
                                  {/*></th>*/}
                                  {/*<th></th>*/}
                                  {/*<th />*/}
                                  {/*/!*<th>Total</th>*!/*/}
                                  {/*<th></th>*/}
                                </tr>
                              </thead>

                              {selectedStaff.map(
                                (
                                  {
                                    nrOfShifts,
                                    employee,
                                    rating,
                                    employee_checkin,
                                  },
                                  index
                                ) => (
                                  <tbody key={`staff-index-${index}`}>
                                    <tr
                                      style={
                                        status === bookingTypes.cancelled
                                          ? {
                                            backgroundColor: colors.tonedGrey,
                                          }
                                          : status === bookingTypes.expired
                                            ? {
                                              backgroundColor: colors.lightGreen,
                                            }
                                            : status === bookingTypes.finished &&
                                              checkoutCompleted
                                              ? {
                                                backgroundColor: colors.lightYellow,
                                              }
                                              : null
                                      }
                                    >
                                      {/*<td>{`ID#: ${employee.id}`}</td>*/}
                                      <td
                                        style={{
                                          padding: checkInStarted
                                            ? "0 0 0 1rem"
                                            : "0 0 0 2rem",
                                          width: "18rem",
                                          color:
                                            nrOfShifts !== 0
                                              ? "#4A4A4A"
                                              : "#F5717F",
                                          display: "flex",
                                        }}
                                      >
                                        {checkInStarted && (
                                          <span
                                            className={`${employee_checkin === false
                                              ? "dot"
                                              : "active-dot"
                                              }`}
                                          ></span>
                                        )}
                                        {`${employee.first_name} ${employee.last_name}`}
                                      </td>
                                      <td style={{ textAlign: 'right' }}>{`DKK ${hourly_wage}`}</td>
                                      <td style={{ display: "inline-block" }}>
                                        {!employee.bank_account_nr ||
                                          (!employee.bank_reg_nr && (
                                            <label className="data-label">
                                              DATA
                                            </label>
                                          ))}
                                      </td>
                                      {/*<td>DKK 2439,00</td>*/}
                                      <td>
                                        {rating.rating && rating.rating > 0 ? (
                                          <ReactStars
                                            count={5}
                                            edit={false}
                                            value={rating.rating}
                                            // onChange={ratingChanged}
                                            size={12}
                                            color2={"#ffd700"}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                            </table>
                          </td>
                          <td>{number_of_staff}</td>
                          <td>{selectedStaff.length}</td>
                          <td>{appliedStaff.length}</td>
                          <td>
                            <div className="date-container">
                              <div>
                                <p className="date">{`${moment.utc(date).format(
                                  "dddd. DD/MM"
                                )} ${start_time} - ${end_time}`}</p>
                                <p>
                                  <span style={{ color: colors.darkGrey }}>
                                    Oprettet:
                                  </span>
                                  {` ${moment(created_at).format(`llll`)}`}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <Button_DEPRECATED
                              onClick={() =>
                                props.takeOverCorporation(
                                  corporation.id,
                                  `/viewBooking/${id}`
                                )
                              }
                              className={"take-over-btn"}
                              title={"Overtag"}
                            />
                          </td>
                          <td>
                            <Button_DEPRECATED
                              onClick={() =>
                                window.open(
                                  `/admin/bookingInvoice/${id}`,
                                  "_blank"
                                )
                              }
                              className={"invoice-btn"}
                              title={"Faktura"}
                            />
                            <Button_DEPRECATED
                              onClick={() =>{
                                console.log({date})
                                const datestr = moment(date).format("MM-DD-YYYY");
                                const weekNumber = moment(datestr, "MM-DD-YYYY").week();
                                window.open(
                                  `/admin/bookingInvoice/${id}?weekNumber=${weekNumber}`,
                                  "_blank"
                                )
                              }
                              }
                              className={"invoice-btn"}
                              title={"VIS UGE"}
                            />
                          </td>
                          <td>
                            {status !== bookingTypes.cancelled &&
                              status !== bookingTypes.expired && (
                                <div className="stats-container">
                                  {all_check_times_approved ? (
                                    <label className="finished">
                                      <CheckIcon />
                                      FAK.
                                    </label>
                                  ) : (
                                    <label className="not-finished">FAK.</label>
                                  )}
                                  {paid_at ? (
                                    <label className="finished">
                                      <CheckIcon />
                                      BET.
                                    </label>
                                  ) : (
                                    <label className="not-finished">BET.</label>
                                  )}

                                  <label className="not-finished">ZEN.</label>
                                </div>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    )
                  }
                )}
              </table>
            )}
          </InfiniteScroll>
        </div>
        {!loading && error && <ErrorBox />}
        {loading && (
          <Spinner
            title={"Indlæser bookinger..."}
            style={{ margin: "2rem 0" }}
          />
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = (colors, tinyColor, tab) => css`
  padding: 0 2rem;
  width: 100%;
  position: absolute;
  font-family: "Arial";
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    .tab-navigator {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
  
      .wrapper {
        position: relative;
        display: inline-block;
        border: 1px solid ${colors.primeBlue};
        border-radius: 3px;
        padding: 0.5rem;
        margin-bottom: 1rem;
  
        button {
          outline: none;
          padding: 0.35rem 0.75rem;
          font-weight: 500;
          border-radius: 3px;
  
          &.active {
            background-color: ${colors.primeBlue};
            color: ${colors.white};
            padding: 0.35rem 0.6rem;
          }
        }
      }
    }
    .week-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 20px;
      visibility: ${tab === 1 ? 'visible' : 'hidden'};

      p {
        padding: 0 1rem;
        font-weight: bold;
        color: ${colors.primeBlue};
        font-size: 1rem;
      }
      .disabled{
        fill: ${colors.darkGrey};
        cursor: not-allowed;
      }
      svg {
        fill: ${colors.primeBlue};
        cursor: pointer;

        &:active {
          fill: ${tinyColor(colors.primeBlue).darken(5).toString()};
        }
      }
    }

    input {
      width: 100%;
      cursor: pointer;
      display: block;
      font-size: 0.75rem;
      outline: none;
      background-color: ${colors.white};
      border: 1px solid ${colors.darkGrey};
      padding: 0.4rem 1rem;
      border-radius: 0.25rem;
      line-height: 1.25;
    }

    p {
      font-size: 2rem;
      margin-right: 0.25rem;
      color: ${colors.darkerRed};
      font-weight: 600;
    }

    p:last-child {
      font-size: 1.5rem;
      font-weight: 500;
      color: ${colors.darkerGrey};
      text-decoration: underline;
    }

  }
  table {
    width: 100%;

    tr {
      border-style: hidden;

      &.finished {
        background-color: ${colors.tonedGrey};
      }

      &.urgent-24-hour {
        background-color: ${tinyColor(colors.lighterRed).lighten(3).toString()};
      }

      &.urgent-48-hour {
        background-color: ${tinyColor(colors.lightYellow).toString()};
      }

      &.full-closed {
        background-color: ${tinyColor(colors.green).lighten(40).toString()};
      }

      &.full {
        background-color: ${tinyColor(colors.green).lighten(47).toString()};
      }
    }

    th {
      text-align: start;
    }
    td {
      border-top: 1px solid ${colors.darkerGrey};
      padding: 0.125rem 0.5rem 0.45rem;
      font-size: 0.85rem;
      vertical-align: baseline;
      font-weight: 500;
      color: ${colors.darkerGrey};
    }
  }
  .no-users-container {
    text-align: center;
  }
  .content {
    width: 100%;
  }

  .table-first-column {
    border-radius: 25px;

    th {
      padding: 0.25rem 0;
      font-size: 0.7rem;
      text-align: start;
      font-weight: 600;
    }
    td {
      padding: 0;
      border: none;
      font-size: 0.65rem;
      vertical-align: middle;
    }
  }

  .date-container {
    display: flex;
    align-items: baseline;

    .date {
      font-weight: 700;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      p {
        font-size: 0.85rem;
      }

      p:last-child {
        font-size: 0.75rem;

        font-style: italic;
      }
    }
  }
  .yellowbtn {
    color: ${colors.custardYellow} !important;
  }
  .greenbtn {
    color: ${colors.darkerGreen} !important;
  }
  .redbtn {
    color: ${colors.darkerRed} !important;
  }

  button {
    width: auto;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
  }

  .corporation-name-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    p {
      color: ${colors.black};
      font-size: 1rem;
      font-weight: 500;
      margin-right: 0.25rem;
    }

    .event-container {
      display: flex;

      .cancelled-label,
      .expired-label,
      .finished-label,
      .closed-label,
      .open-label {
        background-color: ${colors.darkRed};
        color: ${colors.white};
        font-size: 0.7rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        margin: 0.125rem;
        border-radius: 5px;
      }

      .expired-label {
        background-color: ${colors.red};
      }

      .finished-label {
        background-color: ${colors.blue};
      }

      .closed-label {
        background-color: ${colors.custardYellow};
      }

      .open-label {
        background-color: ${colors.green};
      }

      p {
        color: ${colors.darkerGrey};
        font-size: 0.85rem;
        font-weight: 500;
      }
    }
  }

  .dot {
    height: 13px;
    width: 13px;
    background-color: #d1d105;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #00000038;
  }

  .active-dot {
    height: 13px;
    width: 13px;
    background-color: #008000bd;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #00000038;
  }

  .data-label {
    background-color: ${colors.darkerRed};
    color: ${colors.white};
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    border-radius: 5px;
  }

  .take-over-btn,
  .invoice-btn {
    color: ${colors.darkestGrey};
    background-color: transparent !important;
    width: auto;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
  }

  .stats-container {
    display: inline-flex;
    flex-direction: column;
    align-items: end;

    .not-finished,
    .finished {
      background-color: ${colors.darkGrey};
      display: flex;
      color: ${colors.white};
      font-size: 0.7rem;
      font-weight: bold;
      align-items: center;
      padding: 0 0.5rem;
      border-radius: 5px;
      margin: 0.1rem;
    }

    .finished {
      background-color: ${colors.green};
      display: flex;

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.2rem;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  takeOverCorporation: bindActionCreators(takeOverCorporation, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  getAdminBookings: bindActionCreators(getAdminBookings, dispatch),
  changeAllGravyStatus: bindActionCreators(changeAllGravyStatus, dispatch),
  changeStatus: bindActionCreators(changeStatus, dispatch),
  changeSendBlCount: bindActionCreators(changeSendBlCount, dispatch),
  resetBookings: bindActionCreators(resetBookings, dispatch),
  changeWeekNumber: bindActionCreators(changeWeekNumber, dispatch),
  changeTab: bindActionCreators(changeFilterTabUpcomingBookings, dispatch),
});

export default connect(null, mapDispatchToProps)(UpcomingBookings);
