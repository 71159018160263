// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../styles/colors-V-1.0";

// Components
import Label from "./Label";
import Rating from "./Rating";

const CorporationNameAndRating = props => {
  return (
    <div
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
      style={props.style && props.style}
    >
      <Label value={props.name} size={15} className={"name"} />
      <Rating
        edit={false}
        size={10}
        count={5}
        value={parseInt(props.value)}
        className="rating"
        title={
          props.numberOfRatings === 1 ? `${props.numberOfRatings} rating` : `${props.numberOfRatings} ratings`
        }
      />
    </div>
  );
};

const componentStyle = props => css`
  display: flex;
  flex-direction: column;

  .name {
    font-weight: 600;
    color: ${colors.grey_50};
  }

  .rating {
    margin-left: 0.25rem;
  }
`;

export default CorporationNameAndRating;
