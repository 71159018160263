import React from "react";
import { css } from "emotion";
import colors from "../../styles/colors";

const CircularRating = props => {
  if (props.value === 1) {
    return (
      <div className={style(colors,props)}>
        <div className="black" />
        <div className="white" />
        <div className="white" />
        <div className="white" />
        <div className="white" />
      </div>
    );
  } else if (props.value === 2) {
    return (
      <div className={style(colors,props)}>
        <div className="black" />
        <div className="black" />
        <div className="white" />
        <div className="white" />
        <div className="white" />
      </div>
    );
  } else if (props.value === 3) {
    return (
      <div className={style(colors,props)}>
        <div className="black" />
        <div className="black" />
        <div className="black" />
        <div className="white" />
        <div className="white" />
      </div>
    );
  } else if (props.value === 4) {
    return (
      <div className={style(colors,props)}>
        <div className="black" />
        <div className="black" />
        <div className="black" />
        <div className="black" />
        <div className="white" />
      </div>
    );
  } else if (props.value === 5) {
    return (
      <div className={style(colors,props)}>
        <div className="black" />
        <div className="black" />
        <div className="black" />
        <div className="black" />
        <div className="black" />
      </div>
    );
  } else
    return (
      <div className={style(colors,props)}>
        <div className="white" />
        <div className="white" />
        <div className="white" />
        <div className="white" />
        <div className="white" />
      </div>
    );
};

const style = (colors,props) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 4rem;
  margin: ${props.page ? '10px 0 0 10px' : ''};

  div {
    height: 9px;
    width: 9px;
    border: 0.1rem solid ${colors.darkGrey};
    border-radius: 50%;
  }

  .black {
    background-color: ${colors.black};
  }

  .white {
    background-color: ${colors.white};
  }
`;

export default CircularRating;
