// Utilities
import req from "../utilities/request-utility";
import {
  ADD_TOAST,
  GET_STAFFS,
  UPDATE_SKILL_NAME,
  UPDATE_STAFF_SKILLS,
} from "./actionTypes";

export function changeSkillStatus(id, type, value) {
  return async function (dispatch) {
    dispatch({type: UPDATE_STAFF_SKILLS , payload : {id, type, value}})
  }
}

export function changeSkillName(id,skill_name){
  return async function (dispatch) {
    dispatch({type: UPDATE_SKILL_NAME, payload: {id,skill_name}})
  }
}

export function getStaffs() {
  return async function (dispatch, getState) {
    try {
      let state = getState().staff;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;
      
      const staffs = await req()('admin/staffs/get-all-staffs')

      if (staffs.data.length) {
        dispatch({type: GET_STAFFS ,payload:{staffs: staffs.data}})      
      }


    } catch (error) {
      dispatch({
        type: ADD_TOAST,
        payload: {
          message:
            "Noget gik galt, da vi forsøgte at udvælge personalefærdigheder. Hvis fejlen opstår, bedes du kontakte serviceteamet",
          type: "error",
          autoDismiss: true,
        },
      });
    }
  };
}