import {
  GET_STAFFS,
  UPDATE_SKILL_NAME,
  UPDATE_STAFF_SKILLS,
} from "../actions/actionTypes";

const initialState = {
  staffs: [],
  loading: false,
  endOfFeed: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STAFFS:
      return {
        ...state,
        staffs: [...action.payload.staffs],
      };

    case UPDATE_SKILL_NAME:
      return{
        ...state,
        staffs: state.staffs.map((staff) => {
          return staff.id !== action.payload.id ? staff : {...staff, job_title: action.payload.skill_name }
        })
      }

    case UPDATE_STAFF_SKILLS:
      return {
        ...state,
        staffs:
          action.payload.type == "worker"
            ? state.staffs.map((staff) => {
                if (staff.id === action.payload.id) {
                  return { ...staff, worker: !action.payload.value };
                }
                return staff;
              })
            : state.staffs.map((staff) => {
              if (staff.id === action.payload.id) {
                return { ...staff, company: !action.payload.value}
              }
              return staff
            }),
      };


    default:
      return state;
  }
}
