export default {
  superLightGrey: "#F5F5F5",
  red: "#c53030",
  tonedGrey: "#f0f0f0",
  lightRed: "#f56565",
  lighterRed: "#fed7d7",
  darkRed: "#e53e3e",
  darkerRed: "#c92816",

  lightGrey: "#f7fafc",
  mediumGrey: "#edf2f7",
  grey: "#cbd5e0",
  darkGrey: "#718096",
  medDarkGrey: "#696b6a",
  normGrey: "#a0aec0",
  darkerGrey: "#424140",
  placeholder: "#B3B3B3",
  darkestGrey: "#2E2D2C",

  white: "#ffffff",
  mediumBlue: "#4299e1",
  primeBlue: "#3d3546",
  darkerPrimeBlue: "#312b38",
  black: "#000000",
  darkBlue: "#3c75c9",
  darkerBlue: "#1e2f6e",
  lighterGreen: "#B8E986",
  lightGreen: "#f0fff4",
  lightBlue: "#337AB7",
  blue: "#286090",
  greyWhite: "#fefefe",
  green: "#3bad59",
  darkerGreen: "#369c51",
  orange: "#ffb74a",
  lightYellow: "#fffee6",
  custardYellow: "#ecc94b",
  darkCustardYellow: "#C19E3F",
  darkerCustardYellow: "#d69e2e",
  disabledColor: "#dddddd",

  green_50: "#417505",
  grey_50: "#4a4a4a",
  grey_30: "#98989d",



};
