// Libs
import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { css } from "emotion";

// Components
import Spinner from "../../ui/Spinner";
import ErrorBox from "../../ui/ErrorBox";
import ImageUpload from "../../ui/ImageUpload";
import DatePickerUI from "../../ui/DatePickerUI";

// Utilities
import req from "../../../utilities/request-utility";
import { updateUser } from "../../../actions/authActions";
import { addToast } from "../../../actions/uiActions";

// Styles
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import colors from "../../../styles/colors";
import CardBulletedSettingsIcon from "mdi-react/CardBulletedSettingsIcon";
import CarHatchbackIcon from "mdi-react/CarHatchbackIcon";
import { breakPoint } from "../../../styles/breakPoints";

const Account = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [button, setButton] = useState(false);

  const { user, takenOverEmployee } = useSelector(state => state.auth);

  const [updatedUser, setUpdatedUser] = useState(user);

  const [profileSpinner, setProfileSpinner] = useState(false);
  const [uniformSpinner, setUniformSpinner] = useState(false);
  const baseURL_image = process.env.REACT_APP_CLOUDINARY_BASE_URL_IMAGE;
  useEffect(() => {
    if (!_.isEqual(updatedUser, user)) {
      setButton(true);
    } else {
      setButton(false);
    }
    // eslint-disable-next-line
  }, [updatedUser]);

  const update = () => {
    setLoading(true);
    req()
      .put("employees/", updatedUser)
      .then(({ data: employee }) => {
        props.updateUser(employee);
        setLoading(false);
        setButton(false);
        props.addToast({
          message: "Oplysingerne er gemt!",
          type: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        setButton(false);
        props.addToast({
          message:
            "Kunne ikke gemme ændringerne. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };

  const uploadProfileImage = file => {
    const updatedUser = {
      baseURL_image,
      profile_image: file?.imageData?.imageName
    };
    req()
      .put(`employees/`, updatedUser)
      .then(({ data: employee }) => {
        setUpdatedUser(employee);
        props.updateUser(employee);
        props.addToast({
          message: "Billedet er blevet skiftet!",
          type: "success",
          autoDismiss: true
        });
        setProfileSpinner(false);
      })
      .catch(() => {
        setProfileSpinner(false);
        props.addToast({
          message:
            "Kunne ikke gemme ændringerne. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };

  const uploadUniformImage = file => {
    const updatedUser = {
      baseURL_image,
      uniform_image: file?.imageData?.imageName
    };
    req()
      .put(`employees/`, updatedUser)
      .then(({ data: employee }) => {
        setUpdatedUser(employee);
        props.updateUser(employee);
        props.addToast({
          message: "Billedet er blevet skiftet!",
          type: "success",
          autoDismiss: true
        });
        setUniformSpinner(false);
      })
      .catch(() => {
        setUniformSpinner(false);
        props.addToast({
          message:
            "Kunne ikke gemme ændringerne. Hvis fejlen fortsætter, venligst kontakt JobButler",
          type: "warning",
          autoDismiss: true
        });
      });
  };
  const handleCPRChange = (event) => {
    let value = event.target.value;

    // Regular expression to allow only numbers and limit to 8 characters
    const regex = /^[0-9]{0,10}$/;

    // Removing non-numeric characters using regex
    value = value.replace(/\D/g, '');
    
    // Trimming to 8 characters if the length exceeds 8
    if (value.length > 10) {
      value = value.slice(0, 10);
    }


    // If the entered value matches the pattern, update the state
    if (regex.test(value)) {
      setUpdatedUser({
        ...updatedUser,
        [event.target.name]: value
      })
    }
  };

  return (
    <div className={componentStyle()}>
      {!loading && error && <ErrorBox />}
      {loading && !error && <Spinner title={"Indlæser..."} />}
      {!loading && !error && (
        <Fragment>
          <p className="account-title">MIN PROFIL</p>
          <div className="account-top-section">
            <div className="image-container-one">
              <p className="account-image-title">Profilbillede</p>
              {!profileSpinner && (
                <ImageUpload
                  spinner={setProfileSpinner}
                  onFile={file => {
                    uploadProfileImage(file);
                  }}
                  type={"corporationProfile"}
                >
                  <div
                    className={
                      updatedUser.profile_image
                        ? "no-image-account-container"
                        : "image-account-container"
                    }
                  >
                    {updatedUser.profile_image && (
                      <img
                        src={`${updatedUser.baseURL_image}w_1000,h_1000,c_fill,g_faces/${updatedUser.profile_image}`}
                        alt={"icon"}
                      />
                    )}
                    {!updatedUser.profile_image && <p>Ret profilbillede</p>}
                  </div>
                </ImageUpload>
              )}
              {profileSpinner && (
                <div className="loading-account-image-container">
                  <Spinner title={"Indlæser billede"} />
                </div>
              )}
            </div>
            <div className="image-container-one">
              <p className="account-image-title"> Uniform billede</p>

              {!uniformSpinner && (
                <ImageUpload
                  spinner={setUniformSpinner}
                  onFile={file => {
                    uploadUniformImage(file);
                  }}
                  type={"corporationProfile"}
                >
                  <div
                    className={
                      updatedUser.uniform_image
                        ? "no-image-account-container"
                        : "image-account-container"
                    }
                  >
                    {updatedUser.uniform_image && (
                      <img
                        src={`${updatedUser.baseURL_image}w_1000,h_1000,c_fill,g_faces/${updatedUser.uniform_image}`}
                        alt={"icon"}
                      />
                    )}
                    {!updatedUser.uniform_image && <p>Ret uniform billede</p>}
                  </div>
                </ImageUpload>
              )}
              {uniformSpinner && (
                <div className="loading-account-image-container">
                  <Spinner title={"Indlæser billede"} />
                </div>
              )}
            </div>
          </div>

          <div className="account-middle-section">
            <div className="account-section-box">
              <label>Fornavn</label>
              <input
                value={updatedUser.first_name || ''}
                placeholder={updatedUser.first_name}
                name="first_name"
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
              />
            </div>

            <div className="account-section-box-middle">
              <label>Efternavn</label>
              <input
                name="last_name"
                value={updatedUser.last_name}
                placeholder={updatedUser.last_name}
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
              />
            </div>
            <div className="account-section-box">
              <label>Cpr. nr.</label>
              <input
                maxLength={10}
                onChange={handleCPRChange}
                value={updatedUser.cpr_nr || ''}
                name="cpr_nr"
                placeholder={updatedUser?.cpr_nr}
                disabled={user.cpr_nr && user.updatable_in_zenegy && !takenOverEmployee}
              />
            </div>
          </div>

          <hr className="divider" />

          <div className="account-middle-section">
            <div className="account-section-box">
              <label>Post nr.</label>
              <input
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                value={updatedUser.city_zip || ''}
                name="city_zip"
              />
            </div>
            <div className="account-section-box-middle">
              <label>Adresse</label>
              <input
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                value={updatedUser.address || ''}
                name="address"
              />
            </div>
            <div className="account-section-box">
              <label>By</label>
              <input
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                value={updatedUser.city}
                name="city"
              />
            </div>
          </div>

          <div className="account-middle-section">
            <div className="account-section-box">
              <label>Fødseldag</label>

              <DatePickerUI
                defaultValue={moment(updatedUser.birth_date).format(
                  "YYYY-MM-DD"
                )}
                onDateChange={date => {
                  setUpdatedUser({
                    ...updatedUser,
                    birth_date: moment(date, "YYYY-MM-DD")
                  });
                }}
              />
            </div>

            <div className="account-section-box-middle">
              <label>E-mail</label>
              <input
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                value={updatedUser.email}
                name="email"
              />
            </div>
            <div className="account-section-box">
              <label>Mobil nr.</label>
              <input
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                value={updatedUser.phone}
                name="phone"
              />
            </div>
          </div>

          <hr className="divider" />

          <div className="account-middle-section">
            <div className="account-section-box">
              <label>Derfor skal i hyre mig</label>
              <textarea
                value={updatedUser.description}
                name="description"
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                maxLength="255"
                rows="5"
              />
            </div>
          </div>

          <hr className="divider" style={{ marginBottom: "2rem" }} />

          <div className="account-top-section">
            <div className="driver-license-section">
              <div className="driver-box">
                <button
                  name="has_driver_license"
                  onClick={e => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !updatedUser.has_driver_license
                    });
                  }}
                  className={
                    updatedUser.has_driver_license ? "selected" : "not-selected"
                  }
                >
                  JEG HAR KØREKORT
                </button>
                <div
                  className={
                    updatedUser.has_driver_license
                      ? "svg-selected-container"
                      : "svg-container"
                  }
                >
                  <CardBulletedSettingsIcon size={27} />
                </div>
              </div>

              <div className="driver-box">
                <button
                  name="owns_car"
                  onClick={e => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !updatedUser.owns_car
                    });
                  }}
                  className={updatedUser.owns_car ? "selected" : "not-selected"}
                >
                  JEG HAR BIL
                </button>
                <div
                  className={
                    updatedUser.owns_car
                      ? "svg-selected-container"
                      : "svg-container"
                  }
                >
                  <CarHatchbackIcon size={30} />
                </div>
              </div>
            </div>
          </div>

          <hr className="divider" />

          <div className="account-middle-section">
            <div className="account-section-box">
              <label>Reg nr.</label>
              <input
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                value={updatedUser.bank_reg_nr || ''}
                name="bank_reg_nr"
              />
            </div>
            <div className="account-section-box-middle">
              <label>Konto nr. (10 cifre "eks. 0000223344")</label>
              <input
                maxLength={10}
                onChange={e => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value
                  });
                }}
                name="bank_account_nr"
                value={updatedUser.bank_account_nr || ''}
              />
            </div>
            <div className="account-section-box">
              <label>SKATTEKORT</label>
              <select
                name="tax_card_type"
                onChange={e => {
                  setUpdatedUser({ ...updatedUser, [e.target.name]: e.target.value });
                }}
                value={updatedUser?.tax_card_type}
              >
                <option disabled key={`tax-card-0`} value={0}>Vælg</option>
                <option key={`tax-card-2`} value={2}>Bikort</option>
                <option key={`tax-card-1`} value={1}>Hovedkort (Frikort)</option>
              </select>
            </div>
          </div>

          <hr className="divider" style={{ marginBottom: "2rem" }} />

          {button && !loading && (
            <div className="account-btn-section">
              <button onClick={() => update()} className="submit">
                Gem mine ændringer
              </button>
            </div>
          )}

          <p className="account-title">NOTIFIKATIONER</p>
          <table>
            <thead>
              <tr>
                <td>E-mail</td>
                <td>SMS</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <input type="checkbox" 
                  name="noti_info_of_jobs"
                  checked={updatedUser.noti_info_of_jobs}
                  value={updatedUser.noti_info_of_jobs}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    console.log({name: e.target.name, val: e.target.value, valc: !checkVal})
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}
                /></td>
                <td></td>
                <td className="noti-title">Information om jobs</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_info_of_urgent_job"
                  checked={updatedUser.noti_info_of_urgent_job}
                  value={updatedUser.noti_info_of_urgent_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td></td>
                <td className="noti-title">Information om akut job</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_rejected_application"
                  checked={updatedUser.noti_rejected_application}
                  value={updatedUser.noti_rejected_application}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td></td>
                <td className="noti-title">Afvist ansøgning</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_congrets_on_job"
                  checked={updatedUser.noti_congrets_on_job}
                  value={updatedUser.noti_congrets_on_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td><input type="checkbox" name="sms_congrets_on_job"
                  checked={updatedUser.sms_congrets_on_job}
                  value={updatedUser.sms_congrets_on_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td className="noti-title">Tillykke med jobbet</td>
              </tr>
              <tr>
                <td></td>
                <td><input type="checkbox" name="sms_get_ready_for_job"
                  checked={updatedUser.sms_get_ready_for_job}
                  value={updatedUser.sms_get_ready_for_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td className="noti-title">Meld klar til job</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_update_meeting_time"
                  checked={updatedUser.noti_update_meeting_time}
                  value={updatedUser.noti_update_meeting_time}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td><input type="checkbox" name="sms_update_meeting_time"
                  checked={updatedUser.sms_update_meeting_time}
                  value={updatedUser.sms_update_meeting_time}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td className="noti-title">Opdateret mødetid/adresse</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_check_out_job"
                  checked={updatedUser.noti_check_out_job}
                  value={updatedUser.noti_check_out_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td><input type="checkbox" name="sms_check_out_job"
                  checked={updatedUser.sms_check_out_job}
                  value={updatedUser.sms_check_out_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td className="noti-title">Afslut job/stempel ud</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_cancel_job"
                  checked={updatedUser.noti_cancel_job}
                  value={updatedUser.noti_cancel_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td><input type="checkbox" name="sms_cancel_job"
                  checked={updatedUser.sms_cancel_job}
                  value={updatedUser.sms_cancel_job}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }}/></td>
                <td className="noti-title">Annulleret job</td>
              </tr>
            </tbody>
          </table>

        </Fragment>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateUser: bindActionCreators(updateUser, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 5rem;

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding-right: 0.85rem;
    padding-left: 0.85rem;
  }
  table {
    max-width: 60%;
    td.noti-title {
      text-align: left;
      padding-top: 0;
    }

    td{
      text-align: center;
      font-size: 10px;
      padding: 5px 0;
    }
  }
  div.notification-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    p {
      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.75rem;
      }
    }
  }

  p.account-title {
    font-size: 1.25rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.85rem;
    }
  }

  div.account-top-section {
    display: flex;
    justify-content: space-evenly;
    margin-left: 5rem;
    margin-right: 5rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    div.image-container-one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 1.25rem;

      p.account-image-title {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.65rem;
        }
      }

      div.loading-account-image-container {
        display: flex;
        flex-direction: column;
        height: 9.2rem;
        justify-content: center;
        align-items: center;
      }

      div.no-image-account-container,
      div.image-account-container {
        border: 1px solid ${colors.darkestGrey};
        border-radius: 9999px;
        height: 9.2rem;
        width: 9.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        color: ${colors.black};

        @media screen and (max-width: ${breakPoint.sm}px) {
          height: 6rem;
          width: 6rem;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      div.image-account-container {
        text-align: center;
        font-size: 0.85rem;
      }

      div.image-account-container:hover {
        color: ${colors.white};
        background-color: ${colors.darkestGrey};
      }
    }
  }

  div.divider {
    border: 1px solid ${colors.darkerGrey};
  }

  div.account-middle-section {
    display: flex;
    margin: 1rem auto;
    width: 100%;

    div.account-section-box,
    div.account-section-box-middle {
      margin-right: 1rem;
      flex: 1;

      @media screen and (max-width: ${breakPoint.sm}px) {
        margin-right: 0.5rem;
      }

      label {
        text-transform: uppercase;
        letter-spacing: 0.025em;
        color: ${colors.black};
        font-size: 0.6rem;
        margin-bottom: 0.25rem;
        margin-left: 0.25rem;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.5rem;
        }
      }

      input,
      textarea, select {
        cursor: text;
        display: inline-block;
        font-size: 0.75rem;
        outline: none;
        width: 100%;
        background-color: ${colors.white};
        border: 1px solid ${colors.darkGrey};
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        line-height: 1.25;
        -webkit-appearance: none;
        -moz-appearance: none;
        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.55rem;
          padding: 0.25rem 0.5rem;
        }
      }
      select::-ms-expand {
        display: none;
      }
      input:disabled, textarea:disabled {
        background-color: rgb(239, 239, 239)
      }
      input:hover,
      textarea:hover {
        border: 1px solid ${colors.black};
      }

      input::placeholder,
      textarea::placeholder {
        color: ${colors.darkerGrey};
      }
    }

    div.account-section-box-middle {
      flex: 2;
    }

    div.account-section-box:last-of-type {
      margin: 0;
    }
  }

  div.driver-license-section {
    display: flex;
    width: 100%;

    div.driver-box {
      display: flex;
      position: relative;
      width: 100%;
    }

    button.selected,
    button.not-selected {
      outline: none;
      margin: 0.5rem;
      font-size: 0.65rem;
      padding-right: 2rem;
      height: 2.8rem;
      width: 100%;
      background-color: ${colors.white};
      border: 1px solid ${colors.darkerGrey};
      font-weight: bold;
      border-radius: 5px;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.5rem;
      }
    }

    button.not-selected:hover {
      background-color: ${colors.lightGrey};
    }

    button.selected:hover {
      background-color: ${colors.darkerPrimeBlue};
    }

    button.selected {
      color: ${colors.white};
      background-color: ${colors.primeBlue};
    }

    div.svg-container,
    div.svg-selected-container {
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-right: 1.5rem;
      right: 0;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2rem;
      }

      svg {
        fill: ${colors.darkGrey};
      }
    }

    div.svg-selected-container {
      svg {
        fill: ${colors.white};
      }
    }
  }

  .account-btn-section {
    position: fixed;
    bottom: 0;
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: ${breakPoint.sm}px) {
      position: sticky;
    }

    button.submit {
      outline: none;
      background-color: ${colors.lightBlue};
      padding: 0.75rem 1.25rem;
      width: 36rem;
      margin-left: 3rem;
      border-radius: 0.25rem;
      font-weight: bold;
      color: ${colors.white};
      border: 1px solid ${colors.blue};

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: auto;
        font-size: 0.85rem;
        margin: 0;
      }
    }

    button.submit:hover {
      background-color: ${colors.blue};
    }
  }

  hr.divider {
    border-width: 1px;
    border-top-width: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    border-color: ${colors.grey};
  }
`;

export default connect(null, mapDispatchToProps)(Account);
