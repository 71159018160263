// Libs
import React, { Fragment } from "react";
import { css } from "emotion";

// Style
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CancelIcon from "mdi-react/CancelIcon";
import colors from "../../../styles/colors";
import AddressUrl from "mdi-react/ExternalLinkIcon";

// Utilities
import { workHours } from "../../../utilities/get-work-hours-total";

// Components
import DateViewer from "../../ui/DateViewer";
import floatsToMinutesConverter from "../../../utilities/floatsToMinutesConverter";
import { transportWageTimeConverter } from "../../../utilities/transport-wage-time-converter";
import { useSelector } from "react-redux";
import { staffTypeLanguage } from "../../../utilities/language-translator";
import { breakPoint } from "../../../styles/breakPoints";

const BookingDescription = (props) => {
  const { booking } = props;

  const { department } = useSelector((state) => state.auth.user);

  return (
    <div className={componentStyle(colors)}>
      {/* Section 1*/}
      <div className="section-one">
        <div className="date-container">
          <DateViewer className="date-detail" date={booking.date} />
        </div>

        <div className="upper-description">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="arrangement-title">{booking.arrangement_type}</p>
            {booking.approved && (
              <p
                className="staff-type-label"
                style={{
                  marginBottom: "0.5rem",
                  backgroundColor: colors.grey,
                  fontSize: "0.85rem",
                }}
              >
                AKTIV
              </p>
            )}
          </div>

          <div className="price-section">
            <div>
              <div className="time-box">
                <p className="sub-title">
                  {`Kl. ${booking.start_time} - ${
                    booking.end_time
                  } (${workHours({
                    start_time: booking.start_time,
                    end_time: booking.end_time,
                  })} timer)`}
                </p>
                {booking.transport_wage > 0 && (
                  <Fragment>
                    <p style={{ fontSize: "0.75rem" }}>
                      {`+ ${transportWageTimeConverter(
                        booking.transport_wage,
                        "dk"
                      )}`}
                    </p>
                    <p
                      style={{
                        margin: "0 0.25rem",
                        color: colors.darkestGrey,
                        fontSize: "0.7rem",
                        fontWeight: 400,
                      }}
                    >
                      TRANSPORTTILLÆG
                    </p>
                  </Fragment>
                )}
              </div>
              <div className="time-box">
                <p className="sub-title" style={{ fontStyle: "italic" }}>
                  {`${booking.hourly_wage}/t DKK (Løn eks. feriepenge & tillæg)`}
                </p>
              </div>
              <div className="address-box">
                <MapMarkerIcon size={20} />
                {booking.address_url ? (
                  <a
                    href={booking.address_url}
                    target="_blank"
                    style={{ color: colors.darkerGrey, display: "flex" }}
                  >
                    {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                    <AddressUrl className="address-url" />
                  </a>
                ) : (
                  <span style={{ color: colors.darkerGrey, display: "flex" }}>
                    {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                    <AddressUrl className="address-url" />
                  </span>
                )}
              </div>
            </div>
            <div className="need-staff-container">
              {/*<p*/}
              {/*  className="staff-type-label"*/}
              {/*  style={{*/}
              {/*    marginBottom: "0.5rem",*/}
              {/*    backgroundColor: colors.green,*/}
              {/*    fontSize: "0.85rem"*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Aktiv*/}
              {/*</p>*/}
              <p className="staff-type-label">
                {`${booking.number_of_staff} ${booking.jobRole?.job_title}`}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Section 2 INFORMATION */}
      <div className="section-two">
        <div className="info-title-box">
          <p className="text-xl font-hairline text-gray-600">Info</p>
        </div>
        <div className="middle-section">
          <div className="outer-description-container">
            <div className="first-section-container">
              <p>Uddybende jobbeskrivelse</p>
              <p>{booking.job_description}</p>
            </div>
            <div className="first-section-container">
              <p>Hvordan finder personalet frem?</p>
              <p>{booking.access_information}</p>
            </div>
            <div className="first-section-container">
              <p>Ansvarlig ved ankomst:</p>
              <p>{booking.contact_person}</p>
            </div>

            {/*<div className="first-section-container">*/}
            {/*  <p>Booker:</p>*/}
            {/*  <p>{booking.booking_person}</p>*/}
            {/*</div>*/}
            {/*<div className="first-section-container">*/}
            {/*  <p>Booker mobil nr:</p>*/}
            {/*  <p>{booking.booking_person_nr}</p>*/}
            {/*</div>*/}
          </div>
          <div className="required-stuff-container">
            <div className="required-stuff-container-row">
              <div>
                <p>Note til faktura:</p>
              </div>
              <div>
                <p>{booking.invoice_note ? booking.invoice_note : "-"}</p>
              </div>
            </div>
            <div className="required-stuff-container-row">
              <div>
                <p>Afdeling</p>
              </div>
              <div>
                <p>{department || "-"}</p>
              </div>
            </div>
            <div className="required-stuff-container-row">
              <div>
                <p>Overdel:</p>
              </div>
              <div>
                <p>
                  {booking.upper_dress_code
                    ? booking.upper_dress_code
                    : booking.upper_dress_code_other}
                </p>
              </div>
            </div>
            <div className="required-stuff-container-row">
              <div>
                <p>Buks:</p>
              </div>
              <div>
                <p>
                  {booking.lower_dress_code
                    ? booking.lower_dress_code
                    : booking.lower_dress_code_other}
                </p>
              </div>
            </div>
            <div className="required-stuff-container-row">
              <div>
                <p>Sko:</p>
              </div>
              <div>
                <p>
                  {booking.shoes_dress_code
                    ? booking.shoes_dress_code
                    : booking.shoes_dress_code_other}
                </p>
              </div>
            </div>
            <div
              className="required-stuff-container-row"
              style={{ marginBottom: "2rem" }}
            >
              <div>
                <p>Medbring:</p>
              </div>
              <div>
                <p>{booking.item_to_bring || "-"}</p>
              </div>
            </div>

            <div className="required-stuff-container-row">
              <div>
                <p>Overarbejde:</p>
              </div>
              <div>
                <p>
                  {booking.extra_work_hours === 0 ? (
                    <p>Nej</p>
                  ) : (
                    `Der kan forekomme ${floatsToMinutesConverter(
                      booking.extra_work_hours
                    )} overarbejde`
                  )}
                </p>
              </div>
            </div>
            <div className="required-stuff-container-row">
              <div>
                <p>Personalemad:</p>
              </div>
              <div>{booking.food_included ? <p>Ja</p> : <p>Nej</p>}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 PRICE */}
      {/*<div className="section-three">*/}
      {/*  <div className="info-title-box">*/}
      {/*    <p>Pris</p>*/}
      {/*  </div>*/}
      {/*  <div className="last-section">*/}
      {/*    <table>*/}
      {/*      <tr>*/}
      {/*        <th>Beskrivelse</th>*/}
      {/*        <th>Antal</th>*/}
      {/*        <th>Timer</th>*/}
      {/*        <th>Enhedspris</th>*/}
      {/*        <th>Pris</th>*/}
      {/*      </tr>*/}
      {/*      <tr>*/}
      {/*        <td>{staff_type_converter(booking.staff_type)}</td>*/}
      {/*        <td> {booking.number_of_staff}</td>*/}
      {/*        <td>*/}
      {/*          {" "}*/}
      {/*          {workHours({*/}
      {/*            start_time: booking.start_time,*/}
      {/*            end_time: booking.end_time*/}
      {/*          })}*/}
      {/*        </td>*/}
      {/*        <td>{`${addCommas(booking.hourly_wage)} kr`}</td>*/}
      {/*        <td>*/}
      {/*          {`${total_price(*/}
      {/*            booking.number_of_staff,*/}
      {/*            workHours({*/}
      {/*              start_time: booking.start_time,*/}
      {/*              end_time: booking.end_time*/}
      {/*            }),*/}
      {/*            booking.hourly_wage*/}
      {/*          )} kr`}*/}
      {/*        </td>*/}
      {/*      </tr>*/}
      {/*      <tr>*/}
      {/*        <td>Transport</td>*/}
      {/*        <td>*/}
      {/*          {" "}*/}
      {/*          {booking.transport_wage > 0 ? booking.number_of_staff : 0}*/}
      {/*        </td>*/}
      {/*        <td>*/}
      {/*          {" "}*/}
      {/*          {booking.transport_wage*/}
      {/*            ? transport_hours_convert_to_comma(booking.transport_wage)*/}
      {/*            : 0}*/}
      {/*        </td>*/}
      {/*        <td>*/}
      {/*          {" "}*/}
      {/*          {booking.transport_wage*/}
      {/*            ? `${get_transport_fee(*/}
      {/*                booking.hourly_wage,*/}
      {/*                booking.transport_wage*/}
      {/*              )} kr`*/}
      {/*            : `0 kr`}*/}
      {/*        </td>*/}
      {/*        <td>*/}
      {/*          {" "}*/}
      {/*          {booking.transport_wage*/}
      {/*            ? `${get_transport_total_fee_price(*/}
      {/*                booking.number_of_staff,*/}
      {/*                booking.hourly_wage,*/}
      {/*                booking.transport_wage*/}
      {/*              )} kr`*/}
      {/*            : `0 kr`}*/}
      {/*        </td>*/}
      {/*      </tr>*/}
      {/*      <tr>*/}
      {/*        <td>TjenerTeamet tillæg</td>*/}
      {/*        <td> {booking.number_of_staff}</td>*/}
      {/*        <td>*/}
      {/*          {" "}*/}
      {/*          {workHours({*/}
      {/*            start_time: booking.start_time,*/}
      {/*            end_time: booking.end_time*/}
      {/*          })}*/}
      {/*        </td>*/}
      {/*        <td>{`${get_butler_team_fee(*/}
      {/*          booking.hourly_wage,*/}
      {/*          booking.hourly_wage_with_extra*/}
      {/*        )} kr`}</td>*/}
      {/*        <td>{`${get_butler_team_fee_total(*/}
      {/*          booking.number_of_staff,*/}
      {/*          booking.hourly_wage,*/}
      {/*          booking.hourly_wage_with_extra,*/}
      {/*          workHours({*/}
      {/*            start_time: booking.start_time,*/}
      {/*            end_time: booking.end_time*/}
      {/*          })*/}
      {/*        )} kr`}</td>*/}
      {/*      </tr>*/}
      {/*      <div className="separator" />*/}
      {/*      <tr>*/}
      {/*        <td>I alt eks. moms</td>*/}
      {/*        <td>{""}</td>*/}
      {/*        <td>{""}</td>*/}
      {/*        <td>{""}</td>*/}
      {/*        <td>{`${show_booking_total_price(*/}
      {/*          booking.number_of_staff,*/}
      {/*          booking.transport_wage,*/}
      {/*          workHours({*/}
      {/*            start_time: booking.start_time,*/}
      {/*            end_time: booking.end_time*/}
      {/*          }),*/}
      {/*          booking.hourly_wage_with_extra,*/}
      {/*          booking.hourly_wage*/}
      {/*        )} kr`}</td>*/}
      {/*      </tr>*/}
      {/*    </table>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

const componentStyle = (colors) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  .date-detail {
    .day {
      font-size: 1.5rem;
    }

    .date {
      font-size: 2.5rem;
    }

    .month {
      font-size: 1.5rem;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      .day {
        font-size: 0.85rem;
      }

      .date {
        font-size: 1rem;
      }

      .month {
        font-size: 0.85rem;
      }
    }
  }

  p.staff-type-label {
    text-align: center;
    padding: 0 0.5rem;
    background-color: ${colors.lightBlue};
    font-size: 1.15rem !important;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 500;
    letter-spacing: 0.05em;
    width: auto;
    display: table;
    text-transform: uppercase;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 0.55rem !important;
      padding: 0 0.25rem;
      white-space: nowrap;
    }
  }

  .section-one {
    display: flex;

    .date-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 5.2rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 3rem;
      }
    }

    .upper-description {
      display: flex;
      flex-direction: column;
      width: 100%;

      .arrangement-title {
        font-size: 1.25rem;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 0.85rem;
        }
      }

      .price-section {
        display: flex;
        justify-content: space-between;
        background-color: ${colors.white};
        padding: 0.5rem;

        .time-box {
          display: flex;
          align-items: baseline;
          margin-bottom: 0.25rem;

          &:first-child {
            font-weight: bold;
          }

          p {
            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.45rem !important;
            }
          }
        }

        .address-box {
          display: flex;
          align-items: center;

          .address-url {
            height: 15px;
            margin-top: 5px;
          }

          @media screen and (max-width: ${breakPoint.sm}px) {
            svg {
              height: 0.55rem;
              width: 0.55rem;
            }
          }

          p {
            font-size: 0.875rem;
            margin-right: 0.25rem;

            @media screen and (max-width: ${breakPoint.sm}px) {
              margin-right: 0.1rem;
              font-size: 0.45rem;
            }
          }
        }

        .sub-title {
          font-size: 0.85rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.45rem;
          }
        }

        .need-staff-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .info-title-box {
    display: flex;
    justify-content: center;
    width: 5.2rem;

    p {
      font-size: 1.25rem;
      font-weight: 100;
      color: ${colors.darkGrey};
    }
  }

  .section-two {
    display: flex;

    .middle-section {
      display: flex;
      justify-content: space-between;
      background-color: ${colors.white};
      padding: 0.5rem;
      width: 100%;
      margin-bottom: 2rem;

      .outer-description-container {
        width: 23rem;
      }

      .first-section-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        padding-right: 0.5rem;
        white-space: break-spaces;
        p:first-child {
          font-size: 0.85rem;
          font-weight: 600;
        }

        p:last-child {
          font-size: 0.85rem;
          overflow-wrap: break-word;
          white-space: pre-line;
        }
      }

      .required-stuff-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .required-stuff-container-row {
          display: flex;
          margin-bottom: 0.125rem;

          div:first-child {
            width: 8rem;

            p {
              font-size: 0.85rem;
              color: ${colors.darkerGrey};
              font-weight: 600;
            }
          }

          div:last-child {
            flex: 1;
            p {
              font-size: 0.85rem;
              overflow-wrap: break-word;
            }
          }
        }
      }
    }
  }

  .section-three {
    display: flex;

    .last-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${colors.white};
      padding: 0.5rem;
      height: 9.6rem;
      margin-bottom: 1rem;
    }
  }

  table {
    width: 100%;

    td {
      font-size: 0.7rem;
      padding-left: 0.5rem;
      padding-bottom: 0.25rem;
    }

    .separator {
      height: 2rem;
    }

    th {
      font-size: 0.65rem;
      text-align: left;
      padding-bottom: 0.25rem;
      font-weight: 600;
    }

    th:first-child {
      width: 15rem;
    }
  }
`;

export default BookingDescription;
