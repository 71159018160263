// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../../styles/colors-V-1.0";
import { breakPoint } from "../../../styles/breakPoints";
import moneyBagSVG from "../../../styles/icons/money-bag.svg";

// Component
import Logo from "../../ui/Logo";
import CorporationNameAndRating from "../../ui/CorporationNameAndRating";
import DateViewer from "../../ui/DateViewer";
import CorporationBookingOverview from "../../ui/CorporationBookingOverview";
import Label from "../../ui/Label";

// Utilities
import { staffTypeLanguage } from "../../../utilities/language-translator";
import { addCommas } from "../../../utilities/add_commas";
import { remove_commas } from "../../../utilities/remove_commas";
import { workHours } from "../../../utilities/get-work-hours-total";

const WaitingOverviewEmployee = props => {
  const { booking } = props.shift;

  return (
    <div
      onClick={() => props.showBooking({ bookingId: booking.id })}
      style={props.style && props.style}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
    >
      {/** 1. section **/}
      <div className="first-wrapper">
        <div className="logo-wrapper">
          <Logo
            className="logo"
            size={50}
            src={
              booking.corporation.baseURL_image &&
              booking.corporation.icon_image &&
              `${booking.corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${booking.corporation.icon_image}`
            }
          />
        </div>
        <CorporationNameAndRating
          className="corporation-name-and-rating"
          name={booking.corporation.name}
          numberOfRatings={booking.corporation.rating.nrOfRatings}
          value={booking.corporation.rating.rating}
        />
      </div>
      {/** 2. section **/}
      <div className="second-wrapper">
        <div className="date-wrapper">
          <DateViewer className="date-style" size={22} date={booking.date} />
        </div>
        <div className="info-wrapper">
          <CorporationBookingOverview
            arrangementType={booking.arrangement_type}
            address={`${booking.address}, ${booking.zip_code} ${booking.city} `}
            price={`DKK  ${addCommas(
              remove_commas(booking.hourly_wage) *
                workHours({
                  start_time: booking.start_time,
                  end_time: booking.end_time
                })
            )} (${booking.hourly_wage} /t)`}
            time={`Kl. ${booking.start_time} - ${booking.end_time}`}
            staffType={booking.jobRole.job_title}
            priceIcon={moneyBagSVG}
          />
        </div>
      </div>
      {/** 3. section **/}
      <div className="third-wrapper">
        <div className="space-filler" />
        <Label className="label waiting" value={"ANSØGT"} />
      </div>
    </div>
  );
};

const componentStyle = props => css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    border: 1px solid ${colors.grey_10};
  }

  div.first-wrapper {
    display: flex;

    .logo-wrapper {
      width: 4.5rem;
      display: flex;
      justify-content: start;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .logo {
        @media screen and (max-width: ${breakPoint.sm}px) {
          height: ${props.size - 15 || 30}px;
          width: ${props.size - 15 || 30}px;
        }
      }
    }

    .corporation-name-and-rating {
      display: flex;
      justify-content: center;
    }
  }

  div.second-wrapper {
    display: flex;

    .date-wrapper {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .date-style {
        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 20 || 12}px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 17 || 25}px;
          }
        }
      }
    }

    .info-wrapper {
      flex: 1;
      background-color: ${colors.white};
      border-radius: 3px;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
  }

  .third-wrapper {
    display: flex;
    margin-top: 0.25rem;

    .space-filler {
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
        min-width: 2.25rem;
      }
    }

    .label {
      font-weight: bold;
      padding: 0.25rem 1.25rem;
      font-size: 0.65rem;
      border-radius: 3px;
      border: 1px solid ${colors.orange_50};
      display: flex;
      align-items: center;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.4rem;
      }
    }

    .waiting {
      background-color: ${colors.white};
      border-radius: 3px;
      color: ${colors.orange_50};
    }
  }
`;

export default WaitingOverviewEmployee;
