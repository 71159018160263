// Modules
import React, { Fragment, useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Styles
import colors from "../../../styles/colors";

// Validation
import CheckoutValidation from "../../../validation/checkoutValidation";
import { corparationFormValidator } from "../../../validation/newCorporationValidator";

// Actions
import {
  createCorporation,
  createCorporationAndBooking
} from "../../../actions/authActions";
import { addToast, showModal } from "../../../actions/uiActions";

// Components
import PageContainer from "../../ui/PageContainer";
import Spinner from "../../ui/Spinner";
import StepBar from "../../ui/StepBar";
import { useHistory } from "react-router";
import { breakPoint } from "../../../styles/breakPoints";


const NewCorporation = props => {
  const history = useHistory();

  // Validation form
  const validator = new CheckoutValidation(corparationFormValidator);
  // Validation submitted
  const [submitted, setSubmitted] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const { booking } = useSelector(state => state.storage);

  const { loading } = useSelector(state => state.auth);
  const [corporation, setCorporation] = useState({
    name: "",
    address: "",
    email: "",
    billing_email: "",
    department: "",
    password: "",
    city: "",
    city_zip: "",
    cvr_nr: "",
    phone: "",
    contact_person: "",
    terms_accepted_at: new Date(),
    ean: "",
    confirmPassword: "",
    validation: validator.valid(),
    payment_type: 'invoice',
    payment_deadline: 7
  });

  const [validation, setValidation] = useState(corporation.validation);

  const createCorporation = () => {
    const validation = validator.validate(corporation);
    const tempCorporation = { ...corporation };
    tempCorporation.validation = validation;
    setSubmitted(true);
    if (validation.isValid) {
      if (booking) {
        props.createCorporationAndBooking({ booking, corporation });
      } else {
        props.createCorporation(corporation);
      }
    } else {
      // Force rerender component
      setCorporation(tempCorporation);
      // Show warning toaster if booking isn't correct filled
      props.addToast({
        message: "Nogle felter er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(corporation));
    } else {
      setValidation(corporation.validation);
    }
    // eslint-disable-next-line
  }, [corporation]);

  useEffect(() => {
    if (booking) {
      const temp = { ...corporation };
      temp.contact_person = booking.booking_person;
      temp.city = booking.city;
      temp.city_zip = booking.zip_code;
      temp.phone = booking.booking_person_nr;
      temp.address = booking.address;
      setCorporation(temp);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer showNavigation={true} navigationBarType={"corporation"}>
      <div className={componentStyle(colors)}>
        <p className="title">Opret virksomhedsprofil</p>

        {!booking ? (
          <p className="sub-title">
            Opret dig gratis som bruger af ButlerTeamet - du betaler kun, hvis
            du booker personale
          </p>
        ) : (
          <p className="sub-title">
            Det er gratis og oprette jer og du mangler kun at bekræfte booking.
          </p>
        )}

        {!booking ? (
          <div />
        ) : (
          <Fragment>
            <StepBar
              style={{ margin: "2rem" }}
              currentStep={2}
              steps={2}
              titles={["OPRET GRATIS BOOKING", "OPRET VIRKSOMHED"]}
            />
          </Fragment>
        )}

        <div className="corporation-container">
          <div className="extra-div">
            <label>Virksomheden</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.name}
              name="name"
            />
            <p className="invalid-text">{validation.name.message}</p>
          </div>

          <div className="extra-div">
            <label>Adresse</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.address}
              name="address"
            />
            <p className="invalid-text">{validation.address.message}</p>
          </div>
        </div>

        <div className="corporation-container" style={{ marginBottom: "1rem" }}>
          <div className="extra-div">
            <div>
              <label>CVR. nr.</label>
              <input
                onChange={e =>
                  setCorporation({
                    ...corporation,
                    [e.target.name]: e.target.value
                  })
                }
                value={corporation.cvr_nr}
                name="cvr_nr"
              />
              <p className="invalid-text">{validation.cvr_nr.message}</p>
            </div>
          </div>
          <div className="extra-div" style={{ flexDirection: "row" }}>
            <div className="zip-container">
              <label>Post nr.</label>
              <input
                onChange={e =>
                  setCorporation({
                    ...corporation,
                    [e.target.name]: e.target.value
                  })
                }
                value={corporation.city_zip}
                name="city_zip"
              />
              <p className="invalid-text">{validation.city_zip.message}</p>
            </div>
            <div className="address-container">
              <label>By</label>
              <input
                onChange={e =>
                  setCorporation({
                    ...corporation,
                    [e.target.name]: e.target.value
                  })
                }
                value={corporation.city}
                name="city"
              />
              <p className="invalid-text">{validation.city.message}</p>
            </div>
          </div>
        </div>

        <hr style={{ margin: "2rem 0" }} />

        <div className="corporation-container">
          <div className="extra-div">
            <label>Kontaktperson</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.contact_person}
              name="contact_person"
            />
            <p className="invalid-text">{validation.contact_person.message}</p>
          </div>
          <div className="extra-div">
            <label>Afdeling (valgfrit)</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.department}
              name="department"
            />
          </div>
        </div>

        <div className="corporation-container">
          <div className="extra-div">
            <label>Email du vil kontaktes på</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.email}
              name="email"
            />
            <p className="invalid-text">{validation.email.message}</p>
          </div>
          <div className="extra-div">
            <label>Fakturerings email</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.billing_email}
              name="billing_email"
            />
            <p className="invalid-text">{validation.billing_email.message}</p>
          </div>
        </div>

        <div className="corporation-container" style={{ marginBottom: "1rem" }}>
          <div className="extra-div">
            <label>Mobil tlf. nr SMS?</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.phone}
              name="phone"
            />
            <p className="invalid-text">{validation.phone.message}</p>
          </div>
          <div className="extra-div">
            <label>EAN (valgfrit)</label>
            <input
              onChange={e =>
                setCorporation({
                  ...corporation,
                  [e.target.name]: e.target.value
                })
              }
              value={corporation.ean}
              name="ean"
            />
          </div>
        </div>
        <hr />
        <>
          <div className="section-radio">
            <label className="radio-label">
              Betalingtype
            </label>
            <div className="radio-container">
            <div className="radio-div">
                <input
                  checked={corporation.payment_type === "invoice"}
                  type="radio"
                  className="radio"
                  name="payment_type"
                  value={"invoice"}
                  disabled={true}
                />
                <span>Faktura</span>
              </div>
              <div className="radio-div">
                <input
                  checked={corporation.payment_type === "credit_card"}
                  type="radio"
                  className="radio"
                  name="payment_type"
                  value={"credit_card"}
                  disabled={true}
                />
                <span>Kreditkort <br />
                  (kommer snart)</span>
              </div>      
            </div>
          </div>
          <div className="section-radio">
          <label className="radio-label">
            Betalingsfrist
          </label>
          <div className="radio-container">
            <div className="radio-div">
              <input
                checked={parseFloat(corporation.payment_deadline) === 0}
                type="radio"
                className="radio"
                name="payment_deadline"
                value={0}
                disabled={true}
                />
              <span>0 dage <br />
                Hyret personle</span>
            </div>

            <div className="radio-div">
              <input
                checked={parseFloat(corporation.payment_deadline) === 7}
                type="radio"
                className="radio"
                name="payment_deadline"
                value={7}
                disabled={true}
                />
              <span>7 dage <br />
                (kreditgodkendt)</span>
            </div>

            <div className="radio-div">
              <input
                checked={parseFloat(corporation.payment_deadline) === 15}
                type="radio"
                className="radio"
                name="payment_deadline"
                value={15}
                disabled={true}
                />
              <span>15 dage <br />
                (Farvoitkunde)</span>
            </div>

            <div className="radio-div">
              <input
                checked={parseFloat(corporation.payment_deadline) === 30}
                type="radio"
                className="radio"
                name="payment_deadline"
                value={30}
                disabled={true}
                />
              <span className="line-through">30 dage</span>
            </div>
          </div>
          </div>
        </>

        <hr />
        <div style={{marginLeft: '15px'}}>
          <p className="header-title">Kodeord</p>

          <div className="corporation-container" style={{ marginBottom: "1rem" }}>
            <div className="extra-div">
              <label>Kodeord</label>
              <input
                type="password"
                onChange={e =>
                  setCorporation({
                    ...corporation,
                    [e.target.name]: e.target.value
                  })
                }
                value={corporation.password}
                name="password"
              />
              <p className="invalid-text">{validation.password.message}</p>
            </div>
            <div className="extra-div">
              <label>Gentag kodeord</label>
              <input
                type="password"
                onChange={e =>
                  setCorporation({
                    ...corporation,
                    [e.target.name]: e.target.value
                  })
                }
                value={corporation.confirmPassword}
                name="confirmPassword"
              />
              <p className="invalid-text">{validation.confirmPassword.message}</p>
            </div>
          </div>
        </div>

        <hr />
        <div style={{marginLeft: '15px'}}>
          <p className="header-title">Betingelser</p>
          <div className="checkbox-container">
            <input
              checked={acceptedTerms}
              type="checkbox"
              onChange={() => setAcceptedTerms(!acceptedTerms)}
            />
            <p style={{ fontSize: "0.75rem" }}>
              Jeg accepterer{" "}
              <a
                style={{ color: colors.blue }}
                target="_blank"
                href={"https://www.tjenerteamet.dk/handelsbetingelser.html"}
              >
                forretningsbetingelserne
              </a>{" "}
              , og giver samtykke til{" "}
              <a
                style={{ color: colors.blue }}
                target="_blank"
                href={"https://www.tjenerteamet.dk/cookiepolicy.html"}
              >
                databehandling
              </a>{" "}
              som beskrevet her.
            </p>
          </div>
        </div>

        {!loading && (
          <div className="bottom-btn-container">
            {!booking ? (
              <div />
            ) : (
              <button
                className={"back-button"}
                onClick={() => history.push("/")}
              >
                Tilbage
              </button>
            )}

            <button
              className={acceptedTerms ? "submit" : "disabled"}
              disabled={!acceptedTerms}
              onClick={() => createCorporation()}
            >
              Opret Virksomhed
            </button>
          </div>
        )}

        {loading && <Spinner />}
      </div>
    </PageContainer>
  );
};

const componentStyle = colors => css`
  padding: 0 3rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding: 0 1rem;
  }
  .section-radio {
    margin-bottom: 1.25rem;
    margin-left:10px;
    .radio-container {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
  
      .radio-div {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        input {
          margin-bottom: 0.5rem;
        }
  
        span {
          margin-left: 0.5rem;
          font-size: 0.65rem;
          padding-right: 0.5rem;
          text-align: center;
        }
        .line-through {
          text-decoration: line-through;
        }
      }
    }
    
  }

  .checkbox-container {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 2rem;

    //p {
    //  white-space: nowrap;
    //}

    input {
      width: 2.5rem;
      height: 1rem;
      cursor: pointer;
    }

    button {
      color: ${colors.blue};
      outline: none;
    }
  }

  .bottom-btn-container {
    display: flex;
    padding: 0.5rem;
    width: 100%;

    button.submit,
    button.disabled,
    button.back-button {
      background-color: ${colors.primeBlue};
      outline: none;
      color: ${colors.white};
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      text-align: center;
      width: 100%;
      margin: 0 2rem;
    }

    button.back-button {
      background-color: ${colors.blue};
    }

    button.disabled {
      background-color: ${colors.grey};
      cursor: no-drop;
    }

    button.submit:hover {
      background-color: ${colors.darkerPrimeBlue};
    }
  }

  label {
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: ${colors.black};
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
  }

  input {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }
  input:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder {
    color: ${colors.placeholder};
  }

  .invalid-text {
    text-align: start;
    color: ${colors.red};
    font-size: 0.6rem;
    margin-left: 0.5rem;
  }

  .title {
    margin-top: 1rem;
    font-size: 2.25rem;
    margin-bottom: 0.75rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      font-size: 1.5rem;
    }
  }

  .sub-title {
    margin-bottom: 1rem;
    color: ${colors.darkerGrey};
  }

  .header-title {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  .corporation-container {
    display: flex;
    margin-bottom: 0.5rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      flex-direction: column;
    }

    .extra-div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin: 0 0.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 100%;
        margin: 0.5rem 0;
      }
    }
  }

  .zip-container {
    width: 6rem;
    margin-right: 0.5rem;
  }

  .address-container {
    flex: 1;
  }

  hr {
    border-top-width: 1px;
    margin-bottom: 0.5rem;
  }
`;

const mapDispatchToProps = dispatch => ({
  createCorporationAndBooking: bindActionCreators(
    createCorporationAndBooking,
    dispatch
  ),
  showModal: bindActionCreators(showModal, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  createCorporation: bindActionCreators(createCorporation, dispatch)
});

export default connect(null, mapDispatchToProps)(NewCorporation);
