// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

// Styles
import colors from "../../../styles/colors";

// Actions
import { hideModal, showModal } from "../../../actions/uiActions";
import SkillsModal from "./SkillsModal";
import { getWorkSkillQuestion } from "../../../utilities/get-workskill-question";
import { updateWorkSkill } from "../../../actions/authActions";
import Spinner from "../../ui/Spinner";

const WarningSkillsModal = props => {
  const { workSkills } = useSelector(state => state.auth.user);
  const { loadingWorkSkill: loading } = useSelector(state => state.auth);

  const [localWorkSkills, setLocalWorkSkills] = useState([]);

  useEffect(() => {
    let tempWorkSkills = workSkills
      .filter(w => w.job_title === props.jobType)
      .map(w => ({
        value: w.employeeWorkSkill.value,
        skill_type: w.skill_type
      }));

    if (tempWorkSkills.length > 0) setLocalWorkSkills(tempWorkSkills);
  }, [workSkills]);

  return (
    <div className={`${componentStyle(props)}`}>
      {loading && <Spinner title={"Gemmer..."} />}
      {!loading && (
        <>
          <p className="title">
            Du har valgt nogle kompetencer, som du har udfyldt
          </p>
          <p className="sub-title">
            Hvad ønsker du at gøre med de valgte kompetencer?
          </p>
          <div>
            <button className="remove" onClick={() => props.onDelete()}>
              Fjern
            </button>
            {localWorkSkills.length > 0 && (
              <button
                className="edit"
                onClick={() =>
                  props.showModal({
                    content: (
                      <SkillsModal
                        update={skillNumber => {
                          props.updateWorkSkill(skillNumber, () => {
                            props.hideModal();
                          });
                        }}
                        workSkills={localWorkSkills}
                        skillId={props.jobType}
                        skillTitle={props.skillTitle}
                      />
                    )
                  })
                }
              >
                Rediger dem
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const componentStyle = () => css`
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: 18rem;
  min-height: 4rem;

  p.title {
    font-weight: 500;
    font-size: 1.25rem;
  }

  p.sub-title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  button.remove,
  button.edit {
    background-color: ${colors.darkRed};
    color: ${colors.white};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: bold;
    float: right;
    font-size: 0.85rem;
  }

  button.remove:hover {
    background-color: ${colors.red};
  }

  button.edit {
    float: right;
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    margin-right: 0.5rem;
  }

  button.cancel:hover {
    background-color: ${colors.darkGrey};
  }
`;

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(showModal, dispatch),
  updateWorkSkill: bindActionCreators(updateWorkSkill, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(WarningSkillsModal);
