// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

// Utilities
import req from "../../utilities/request-utility";

// Actions
import { addToast, hideModal } from "../../actions/uiActions";

// Components
import Spinner from "../ui/Spinner";
import ErrorBox from "../ui/ErrorBox";

// Styles
import ReactStars from "react-stars";

const PastEmployeeRatings = props => {
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    req()(`ratings/${props.employeeId}`)
      .then(({ data: ratings }) => {
        setRatings(ratings);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        props.addToast({
          message: "Noget gik galt",
          type: "error",
          autoDismiss: true
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle()}>
      {loading && !error && <Spinner title={"Indlæser ratings"} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <div>
          <p>{`Jobs taget: ${ratings.length ||
            0} (Gnm. rating: ${props.averageRating || 0})`}</p>
          <table>
            <tbody>
              {ratings.map(
                ({ score, corporation, booking, created_at, comment, job_title }) => (
                  <tr>
                    <td>
                      <p>{moment(booking.date).format("ll")}</p>
                    </td>
                    <td>
                      <ReactStars
                        edit={false}
                        size={17}
                        value={score}
                        className="stars-container"
                      />
                    </td>
                    <td>
                      <div className="container-past-ratings">
                        <p style={{ fontWeight: "bold" }}>{corporation.name}</p>
                        <p>{`${job_title} - ${
                          booking.arrangementType
                        }`}</p>
                        <p style={{ fontStyle: "italic" }}>{comment || ""}</p>
                      </div>
                    </td>

                    {/*<td>*/}
                    {/*  <SingleImageContainer*/}
                    {/*    className="image-container"*/}
                    {/*    baseURL={corporation.image.baseURL}*/}
                    {/*    image={corporation.image.image}*/}
                    {/*  />*/}
                    {/*</td>*/}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1rem;
  max-height: 30rem;

  div.image-container {
    img {
      width: 3rem;
      height: 3rem;
    }
  }

  .container-past-ratings {
    display: flex;
    flex-direction: column;
  }

  table {
    border-spacing: 1rem 0.5rem;
    border-collapse: separate;

    tr {
      vertical-align: top;
    }

    th {
      text-align: start;
    }

    td {
      font-size: 0.85rem;
      text-align: start;
    }
  }

  .stars-container {
    display: flex;
  }
`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch)
});

export default connect(null, mapDispatchToProps)(PastEmployeeRatings);
