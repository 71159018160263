export const hours = [
  { value: "00" },
  { value: "01" },
  { value: "02" },
  { value: "03" },
  { value: "04" },
  { value: "05" },
  { value: "06" },
  { value: "07" },
  { value: "08" },
  { value: "09" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
  { value: "16" },
  { value: "17" },
  { value: "18" },
  { value: "19" },
  { value: "20" },
  { value: "21" },
  { value: "22" },
  { value: "23" },
  { value: "24" },
];

export const minutes = [{ value: "00" }, { value: "30" }];
export const fifteenMinutes = [
  { value: "00" },
  { value: "15" },
  { value: "30" },
  { value: "45" },
];

export const staffNumber = [
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
  { value: "13" },
  { value: "14" },
  { value: "15" },
];

export const staff = [
  { type: "Tjener", value: "waiter" },
  { type: "Bartender", value: "bartender" },
];

export const shoes = [
  { name: "Sorte sko", value: "Sorte sko" },
  { name: "Pæne sorte sko", value: "Pæne sorte sko" },
  { name: "Angiv sko", value: "" },
];

export const lowerDress = [
  { name: "Sort buks", value: "Sort buks" },
  { name: "Sort pæn buks", value: "Sort pæn buks" },
  { name: "Angiv buks", value: "" },
];

export const upperDress = [
  { name: "Sort skjorte", value: "Sort skjorte" },
  { name: "Hvid skjorte", value: "Hvid skjorte" },
  { name: "Angiv overdel", value: "" },
];

export const events = [
  { name: "Firma event", value: "Firma event" },
  { name: "Bryllup", value: "Bryllup" },
  { name: "Julefrokost", value: "Julefrokost" },
  { name: "Reception", value: "Reception" },
  { name: "Butiksåbnings", value: "Butiksåbnings" },
  { name: "Eksklusivt møde", value: "Eksklusivt møde" },
  { name: "A la carte ", value: "A la carte" },
  { name: "Angiv type booking", value: "" },
];

export const accessTypes = [
  { name: "Via hovedindgangen", value: "Via hovedindgangen" },
  { name: "Bagdøren", value: "Bagdøren" },
  { name: "Angiv selv", value: "" },
];

export const languages = [
  {name: `Dansk 🇩🇰`, value: "Dansk"},
  {name: `Engelsk 🇬🇧`, value: "Engelsk"},
  {name: `Dansk 🇩🇰  el. Engelsk 🇬🇧`, value: `Dansk el. Engelsk`}
]
